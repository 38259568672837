.claim__actions {
  position: -webkit-sticky;
  position: sticky;
  top: 40px;
  background-color: white;
  font-size: 20px;
  z-index: 6;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 12px;
  padding-top: 15px;
  padding-right: 20px;
  margin-top: -20px !important;
  box-shadow: 0px 0px 16px rgba(17, 17, 26, 0.1) !important;
}

.claim__action--form-group {
  margin-top: 2px;
  margin-right: 20px;
  margin-bottom: 0;
}

.claim__action--form-group > label {
  margin-bottom: 0px;
  margin-top: 10px;
}

.claim__actions > button {
  z-index: 70 !important;
  opacity: 1 !important;
  margin-right: 5px;
  border-radius: 4px !important;
  box-shadow: 0px 4px 16px rgba(17, 17, 26, 0.05),
    0px 8px 32px rgba(17, 17, 26, 0.05) !important;

  padding: 10px 20px;
}

.claim__actions > span {
  font-size: 1.4rem;
  padding-right: 1rem;
  padding-top: 1.3rem;
  font-weight: bold;
}

.claim__actions--saving {
  color: tomato;
}

.claim__actions--loading {
  color: #2c3e50;
}

.claim__actions--last-saved {
  color: #9c9a9a;
  font-weight: normal !important;
}
