.daterangepicker{
    display: none;
}
.drp-calendar.right{
    float: right;
    margin: 10px;
}
.drp-calendar.left{
    float: left;
    margin: 10px;
}
.drp-buttons{
    text-align: center;
}
.drp-buttons > .cancelBtn, .drp-buttons > .applyBtn{
    margin-left: 10px;
}
.dataTables_wrapper .prev, .dataTables_wrapper .next{
    padding: 0px 10px;
}