.label-prospect{
  background-color: #9A12B3;  
}
.label-active{
  background-color: #26C281;  
}
.label-settled{
  background-color: #F4D03F;  
}
.label-attorney{
  background-color: red;  
}
.label-closed{
  background-color: black;  
}
.label-appraisal{
  background-color: #EF4836; 
}
.label-umpire{
  background-color: #ff9800; 
}
.label-mediation{
  background-color: #00A1FF; 
}
.kOizLa.active{
  color: #1bbc9b;
}