body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.mt-30 {
  margin-top: 30px;
}

.br-4 {
  border-radius: 4px !important;
}

.border-radius-5 {
  border-radius: 5px !important;
}

.avatar-edit-profile-circle {
  width: 140px;
  height: 140px;
  background-color: #fff1f3;
  text-align: center;
  border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  margin-right: 5px;
  display: inline-block;
}

.initials-edit-profile {
  position: relative;
  top: 61px; /* 25% of parent */
  font-size: 26px; /* 50% of parent */
  line-height: 16px; /* 50% of parent */
  color: black;
  text-transform: uppercase;
}
.dropzoneLabel {
  font-size: 16px;
  font-weight: 700;
}
.dropzone {
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  border: 2px dashed #ebedf2;
}

.sweet-alert > div,
.sweet-alert > div > div {
  border-radius: 50% !important;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
  background: #364150;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(187, 187, 187, 0.8);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(187, 187, 187);
}

.ReactModal__Overlay--after-open {
  background-color: rgba(0, 0, 0, 0.5) !important;
  border-radius: "8px!important";
  z-index: 9999;
}

.genrate-document-button {
  border-top: 2px solid #fff !important;
  border-bottom: 2px solid #fff !important;
  border-radius: 0px !important;
  margin-left: 3% !important;
  margin-right: 3% !important;
}

.client-edit-top-bar {
  background: #e1e5eb !important;
  margin: 4px 0px 2px 0px !important;
}
.client-edit-top-bar-button {
  background-color: #e1e5eb !important;
  border: 1px solid #cdcdcd !important;
}

.client-edit-top-bar-button:hover,
.client-edit-top-bar-button:active {
  background-color: #c5cbd5 !important;
}

.client-edit-top-bar > li {
  color: black;
}

.client-edit-mid-tab {
  margin-bottom: 0px !important;
  /* background: #e1e5eb; */
}

.client-edit-mid-tab > li {
  width: 16% !important;
  text-align: center;
}

.client-edit-mid-attachment-tab {
  border: none !important;
}

.client-edit-mid-attachment-tab > li {
  width: 19% !important;
}

.attachment-folder-rename-button {
  float: right;
  margin: 4px;
}
.client-edit-mid-tab > li > a {
  color: #555;
  padding: 7px !important;
}

.client-edit-mid-tab > li.active > a {
  border-top: 3px solid #de7378 !important;
  padding: 7px !important;
  border-radius: 4px;
}

.client-edit-mid-tab-content {
  padding: 10px;
}

.multiSelectDropdown > input:checked {
  background: red;
}
/* date picker css */
.react-datepicker-wrapper {
  width: 100%;
}

.blue-primary {
  border-color: #1059d3 !important;
  color: #1059d3 !important;
  background: 0 0;
}

.blue-primary:hover {
  border-color: #1059d3;
  color: #fff !important;
  background-color: #1059d3;
}

.blue-primary-solid {
  border-color: #1059d3;
  color: #fff !important;
  background-color: #1059d3;
}

.blue-primary-solid:hover {
  border-color: #1059d3 !important;
  color: #1059d3 !important;
  background: 0 0;
}

.no-border {
  border: none !important;
}

.dataTables_filter {
  float: left !important;
  text-align: left !important;
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 5px;
}
