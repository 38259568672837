.option-title {
  font-weight: bold;
}

.heading > h3 {
  font-weight: bold !important;
}

.build-version {
  text-align: center;
  font-style: normal;
  color: #8d949e;
}
