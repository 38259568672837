/* Cubic Bezier Transition */
/*********** 
    Page Header
    ***********/
/* Header search bar, toggler button & top menu */
.page-header.navbar {
  background-color: #2b3643;
  /* Top notification menu/bar */
  /* Header seaech box */
  /* Toggler button for sidebar expand/collapse and responsive sidebar menu */
}
.page-header.navbar .top-menu .navbar-nav {
  /* Extended Dropdowns */
  /* Notification */
  /* Inbox */
  /* Tasks */
  /* User */
  /* Language */
  /* Dark version */
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown .dropdown-toggle > i {
  color: #79869a;
}
.page-header.navbar
  .top-menu
  .navbar-nav
  > li.dropdown
  .dropdown-toggle
  .badge.badge-default {
  background-color: #1bbc9b;
  color: #ffffff;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown .dropdown-toggle:hover {
  background-color: #3f4f62;
}
.page-header.navbar
  .top-menu
  .navbar-nav
  > li.dropdown
  .dropdown-toggle:hover
  > i {
  color: #a4aebb;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown.open .dropdown-toggle {
  background-color: #3f4f62;
}
.page-header.navbar
  .top-menu
  .navbar-nav
  > li.dropdown.open
  .dropdown-toggle
  > i {
  color: #a4aebb;
}
.page-header.navbar
  .top-menu
  .navbar-nav
  > li.dropdown-extended
  .dropdown-menu {
  border-color: #e7eaf0;
}
.page-header.navbar
  .top-menu
  .navbar-nav
  > li.dropdown-extended
  .dropdown-menu:after {
  border-bottom-color: #eaedf2;
}
.page-header.navbar
  .top-menu
  .navbar-nav
  > li.dropdown-extended
  .dropdown-menu
  > li.external {
  background: #eaedf2;
}
.page-header.navbar
  .top-menu
  .navbar-nav
  > li.dropdown-extended
  .dropdown-menu
  > li.external
  > h3 {
  color: #62878f;
}
.page-header.navbar
  .top-menu
  .navbar-nav
  > li.dropdown-extended
  .dropdown-menu
  > li.external
  > a {
  color: #337ab7;
}
.page-header.navbar
  .top-menu
  .navbar-nav
  > li.dropdown-extended
  .dropdown-menu
  > li.external
  > a:hover {
  color: #23527c;
  text-decoration: none;
}
.page-header.navbar
  .top-menu
  .navbar-nav
  > li.dropdown-extended
  .dropdown-menu
  .dropdown-menu-list
  > li
  > a {
  border-bottom: 1px solid #eff2f6 !important;
  color: #888888;
}
.page-header.navbar
  .top-menu
  .navbar-nav
  > li.dropdown-extended
  .dropdown-menu
  .dropdown-menu-list
  > li
  > a:hover {
  background: #f8f9fa;
}
.page-header.navbar
  .top-menu
  .navbar-nav
  > li.dropdown-notification
  .dropdown-menu
  .dropdown-menu-list
  > li
  > a
  .time {
  background: #f1f1f1;
}
.page-header.navbar
  .top-menu
  .navbar-nav
  > li.dropdown-notification
  .dropdown-menu
  .dropdown-menu-list
  > li
  > a:hover
  .time {
  background: #e4e4e4;
}
.page-header.navbar
  .top-menu
  .navbar-nav
  > li.dropdown-inbox
  > .dropdown-toggle
  > .circle {
  background-color: #1bbc9b;
  color: #ffffff;
}
.page-header.navbar
  .top-menu
  .navbar-nav
  > li.dropdown-inbox
  > .dropdown-toggle
  > .corner {
  border-color: transparent transparent transparent #1bbc9b;
}
.page-header.navbar
  .top-menu
  .navbar-nav
  > li.dropdown-inbox
  .dropdown-menu
  .dropdown-menu-list
  .subject
  .from {
  color: #5b9bd1;
}
.page-header.navbar
  .top-menu
  .navbar-nav
  > li.dropdown-tasks
  .dropdown-menu
  .dropdown-menu-list
  .progress {
  background-color: #dfe2e9;
}
.page-header.navbar
  .top-menu
  .navbar-nav
  > li.dropdown-user
  > .dropdown-toggle
  > .username {
  color: #c6cfda;
}
.page-header.navbar
  .top-menu
  .navbar-nav
  > li.dropdown-user
  > .dropdown-toggle
  > i {
  color: #c6cfda;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-user > .dropdown-menu {
  width: 195px;
}
.page-header.navbar
  .top-menu
  .navbar-nav
  > li.dropdown-language
  > .dropdown-toggle
  > .langname {
  color: #c6cfda;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu {
  background: #3f4f62;
  border: 0;
}
.page-header.navbar
  .top-menu
  .navbar-nav
  > li.dropdown-dark
  .dropdown-menu:after {
  border-bottom-color: #3f4f62;
}
.page-header.navbar
  .top-menu
  .navbar-nav
  > li.dropdown-dark
  .dropdown-menu
  > li.external {
  background: #2f3b49;
}
.page-header.navbar
  .top-menu
  .navbar-nav
  > li.dropdown-dark
  .dropdown-menu
  > li.external
  > h3 {
  color: #adbaca;
}
.page-header.navbar
  .top-menu
  .navbar-nav
  > li.dropdown-dark
  .dropdown-menu
  > li.external
  > a:hover {
  color: #5496cf;
}
.page-header.navbar
  .top-menu
  .navbar-nav
  > li.dropdown-dark
  .dropdown-menu.dropdown-menu-default
  > li
  a,
.page-header.navbar
  .top-menu
  .navbar-nav
  > li.dropdown-dark
  .dropdown-menu
  .dropdown-menu-list
  > li
  a {
  color: #bcc7d4;
  border-bottom: 1px solid #4b5e75 !important;
}
.page-header.navbar
  .top-menu
  .navbar-nav
  > li.dropdown-dark
  .dropdown-menu.dropdown-menu-default
  > li
  a
  > i,
.page-header.navbar
  .top-menu
  .navbar-nav
  > li.dropdown-dark
  .dropdown-menu
  .dropdown-menu-list
  > li
  a
  > i {
  color: #9dadc0;
}
.page-header.navbar
  .top-menu
  .navbar-nav
  > li.dropdown-dark
  .dropdown-menu.dropdown-menu-default
  > li
  a:hover,
.page-header.navbar
  .top-menu
  .navbar-nav
  > li.dropdown-dark
  .dropdown-menu
  .dropdown-menu-list
  > li
  a:hover {
  background: #47596e;
}
.page-header.navbar
  .top-menu
  .navbar-nav
  > li.dropdown-dark
  .dropdown-menu.dropdown-menu-default
  > li
  a {
  border-bottom: 0 !important;
}
.page-header.navbar
  .top-menu
  .navbar-nav
  > li.dropdown-dark
  .dropdown-menu.dropdown-menu-default
  > li.divider {
  background: #4b5e75;
}
.page-header.navbar
  .top-menu
  .navbar-nav
  > li.dropdown-notification.dropdown-dark
  .dropdown-menu
  .dropdown-menu-list
  > li
  > a
  .time {
  background: #354353;
}
.page-header.navbar
  .top-menu
  .navbar-nav
  > li.dropdown-notification.dropdown-dark
  .dropdown-menu
  .dropdown-menu-list
  > li
  > a:hover
  .time {
  background: #2b3643;
}
.page-header.navbar .search-form {
  background: #232c37;
}
.page-header.navbar .search-form:hover {
  background: #3f4f62;
}
.page-header.navbar .search-form .input-group .form-control {
  color: #959fad;
}
.page-header.navbar .search-form .input-group .form-control::-moz-placeholder {
  color: #929cab;
  opacity: 1;
}
.page-header.navbar
  .search-form
  .input-group
  .form-control:-ms-input-placeholder {
  color: #929cab;
}
.page-header.navbar
  .search-form
  .input-group
  .form-control::-webkit-input-placeholder {
  color: #929cab;
}
.page-header.navbar .search-form .input-group .input-group-btn .btn.submit > i {
  color: #959fad;
}
.page-header.navbar .search-form.open {
  background: #3f4f62;
}
.page-header.navbar .menu-toggler > span,
.page-header.navbar .menu-toggler > span:before,
.page-header.navbar .menu-toggler > span:after {
  background: #a7b5c6;
}
.page-header.navbar .menu-toggler > span:hover {
  background: #a7b5c6;
}
.page-header.navbar .menu-toggler > span:hover:before,
.page-header.navbar .menu-toggler > span:hover:after {
  background: #a7b5c6;
}
.page-header.navbar .menu-toggler.th-toggle-exit > span {
  background-color: transparent !important;
}

/* Default Horizontal Menu */
.page-header.navbar {
  /* Default Mega Menu */
  /* Light Mega Menu */
}
.page-header.navbar .hor-menu .navbar-nav {
  /* Mega menu content */
  /* Classic menu */
}
.page-header.navbar
  .hor-menu
  .navbar-nav
  > li.mega-menu-dropdown
  > .dropdown-menu {
  box-shadow: 5px 5px rgba(63, 79, 98, 0.2);
}
.page-header.navbar
  .hor-menu
  .navbar-nav
  > li.mega-menu-dropdown
  > .dropdown-menu
  .mega-menu-content
  .mega-menu-submenu
  li
  > h3 {
  color: #c6cfda;
}
.page-header.navbar .hor-menu .navbar-nav > li > a {
  color: #c6cfda;
}
.page-header.navbar .hor-menu .navbar-nav > li > a > i {
  color: #788ea8;
}
.page-header.navbar .hor-menu .navbar-nav > li:hover > a,
.page-header.navbar .hor-menu .navbar-nav > li.open > a,
.page-header.navbar .hor-menu .navbar-nav > li > a:hover {
  color: #d5dce4;
  background: #3f4f62 !important;
}
.page-header.navbar .hor-menu .navbar-nav > li:hover > a > i,
.page-header.navbar .hor-menu .navbar-nav > li.open > a > i,
.page-header.navbar .hor-menu .navbar-nav > li > a:hover > i {
  color: #889bb2;
}
.page-header.navbar .hor-menu .navbar-nav > li.active > a,
.page-header.navbar .hor-menu .navbar-nav > li.active > a,
.page-header.navbar .hor-menu .navbar-nav > li.current > a,
.page-header.navbar .hor-menu .navbar-nav > li.current > a {
  color: white;
  background: #1bbc9b !important;
}
.page-header.navbar .hor-menu .navbar-nav > li.active > a > i,
.page-header.navbar .hor-menu .navbar-nav > li.active > a > i,
.page-header.navbar .hor-menu .navbar-nav > li.current > a > i,
.page-header.navbar .hor-menu .navbar-nav > li.current > a > i {
  color: #788ea8;
}
.page-header.navbar .hor-menu .navbar-nav > li.active .selected,
.page-header.navbar .hor-menu .navbar-nav > li.current .selected {
  border-top: 6px solid #1bbc9b;
}
.page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu {
  box-shadow: 5px 5px rgba(63, 79, 98, 0.2);
  background: #3f4f62;
}
.page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu li > a {
  color: #c6cfda;
}
.page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu li > a > i {
  color: #c6cfda;
}
.page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu li:hover > a {
  color: #f1f3f6;
  background: #47596e;
}
.page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu li:hover > a > i {
  color: #f1f3f6;
}
.page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu li.active > a,
.page-header.navbar
  .hor-menu
  .navbar-nav
  > li
  .dropdown-menu
  li.active
  > a:hover,
.page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu li.current > a,
.page-header.navbar
  .hor-menu
  .navbar-nav
  > li
  .dropdown-menu
  li.current
  > a:hover {
  color: #f1f3f6;
  background: #47596e;
}
.page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu li.active > a > i,
.page-header.navbar
  .hor-menu
  .navbar-nav
  > li
  .dropdown-menu
  li.active
  > a:hover
  > i,
.page-header.navbar
  .hor-menu
  .navbar-nav
  > li
  .dropdown-menu
  li.current
  > a
  > i,
.page-header.navbar
  .hor-menu
  .navbar-nav
  > li
  .dropdown-menu
  li.current
  > a:hover
  > i {
  color: #f1f3f6;
}
.page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu li.divider {
  background-color: #495c72;
}
.page-header.navbar .hor-menu .navbar-nav > li .dropdown-submenu > a:after {
  color: #c6cfda;
}
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav {
  /* Mega menu content */
  /* Classic menu */
}
.page-header.navbar
  .hor-menu.hor-menu-light
  .navbar-nav
  > li.mega-menu-dropdown
  > .dropdown-menu {
  box-shadow: 5px 5px rgba(102, 102, 102, 0.1);
}
.page-header.navbar
  .hor-menu.hor-menu-light
  .navbar-nav
  > li.mega-menu-dropdown
  > .dropdown-menu
  .mega-menu-content
  .mega-menu-submenu
  li
  > h3 {
  color: #666;
}
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li > a {
  color: #c6cfda;
}
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li > a > i {
  color: #788ea8;
}
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li:hover > a,
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li > a:hover {
  color: #d5dce4;
  background: #3f4f62;
}
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li:hover > a > i,
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li > a:hover > i {
  color: #889bb2;
}
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li.open > a {
  color: #333 !important;
  background: white !important;
}
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li.open > a > i {
  color: #333 !important;
}
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li.active > a,
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li.active > a:hover,
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li.current > a,
.page-header.navbar
  .hor-menu.hor-menu-light
  .navbar-nav
  > li.current
  > a:hover {
  color: white;
  background: #1bbc9b;
}
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li.active > a > i,
.page-header.navbar
  .hor-menu.hor-menu-light
  .navbar-nav
  > li.active
  > a:hover
  > i,
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li.current > a > i,
.page-header.navbar
  .hor-menu.hor-menu-light
  .navbar-nav
  > li.current
  > a:hover
  > i {
  color: #788ea8;
}
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li .dropdown-menu {
  box-shadow: 5px 5px rgba(102, 102, 102, 0.1);
  background: white;
}
.page-header.navbar
  .hor-menu.hor-menu-light
  .navbar-nav
  > li
  .dropdown-menu
  li
  > a {
  color: #000;
}
.page-header.navbar
  .hor-menu.hor-menu-light
  .navbar-nav
  > li
  .dropdown-menu
  li
  > a
  > i {
  color: #888;
}
.page-header.navbar
  .hor-menu.hor-menu-light
  .navbar-nav
  > li
  .dropdown-menu
  li:hover
  > a {
  color: #000;
  background: whitesmoke;
}
.page-header.navbar
  .hor-menu.hor-menu-light
  .navbar-nav
  > li
  .dropdown-menu
  li:hover
  > a
  > i {
  color: #666;
}
.page-header.navbar
  .hor-menu.hor-menu-light
  .navbar-nav
  > li
  .dropdown-menu
  li.active
  > a,
.page-header.navbar
  .hor-menu.hor-menu-light
  .navbar-nav
  > li
  .dropdown-menu
  li.active
  > a:hover,
.page-header.navbar
  .hor-menu.hor-menu-light
  .navbar-nav
  > li
  .dropdown-menu
  li.current
  > a,
.page-header.navbar
  .hor-menu.hor-menu-light
  .navbar-nav
  > li
  .dropdown-menu
  li.current
  > a:hover {
  color: #000;
  background: whitesmoke;
}
.page-header.navbar
  .hor-menu.hor-menu-light
  .navbar-nav
  > li
  .dropdown-menu
  li.active
  > a
  > i,
.page-header.navbar
  .hor-menu.hor-menu-light
  .navbar-nav
  > li
  .dropdown-menu
  li.active
  > a:hover
  > i,
.page-header.navbar
  .hor-menu.hor-menu-light
  .navbar-nav
  > li
  .dropdown-menu
  li.current
  > a
  > i,
.page-header.navbar
  .hor-menu.hor-menu-light
  .navbar-nav
  > li
  .dropdown-menu
  li.current
  > a:hover
  > i {
  color: #666;
}
.page-header.navbar
  .hor-menu.hor-menu-light
  .navbar-nav
  > li
  .dropdown-menu
  li.divider {
  background-color: whitesmoke;
}
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li .dropdown-menu {
  border: 1px solid #f2f2f2;
}
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li > .dropdown-menu {
  border-top: 0;
}

/* Page sidebar */
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover,
.page-sidebar {
  background-color: #364150;
  /* Default sidebar */
  /* light sidebar */
  /* Sidebar search */
}
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu,
.page-sidebar .page-sidebar-menu {
  /* 1st level links */
  /* All links */
}
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu
  > li
  > a,
.page-sidebar .page-sidebar-menu > li > a {
  border-top: 1px solid #3d4957;
  color: #b4bcc8;
}
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu
  > li
  > a
  > i,
.page-sidebar .page-sidebar-menu > li > a > i {
  color: #606c7d;
}
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu
  > li
  > a
  > i[class^="icon-"],
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu
  > li
  > a
  > i[class*="icon-"],
.page-sidebar .page-sidebar-menu > li > a > i[class^="icon-"],
.page-sidebar .page-sidebar-menu > li > a > i[class*="icon-"] {
  color: #6b788b;
}
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu
  > li
  > a
  > .arrow:before,
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu
  > li
  > a
  > .arrow.open:before,
.page-sidebar .page-sidebar-menu > li > a > .arrow:before,
.page-sidebar .page-sidebar-menu > li > a > .arrow.open:before {
  color: #606c7d;
}
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu
  > li.heading
  > h3,
.page-sidebar .page-sidebar-menu > li.heading > h3 {
  color: #708096;
}
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu
  > li:hover
  > a,
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu
  > li.open
  > a,
.page-sidebar .page-sidebar-menu > li:hover > a,
.page-sidebar .page-sidebar-menu > li.open > a {
  background: #2c3542;
  color: #b4bcc8;
}
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu
  > li:hover
  > a
  > i,
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu
  > li.open
  > a
  > i,
.page-sidebar .page-sidebar-menu > li:hover > a > i,
.page-sidebar .page-sidebar-menu > li.open > a > i {
  color: #606c7d;
}
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu
  > li:hover
  > a
  > .arrow:before,
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu
  > li:hover
  > a
  > .arrow.open:before,
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu
  > li.open
  > a
  > .arrow:before,
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu
  > li.open
  > a
  > .arrow.open:before,
.page-sidebar .page-sidebar-menu > li:hover > a > .arrow:before,
.page-sidebar .page-sidebar-menu > li:hover > a > .arrow.open:before,
.page-sidebar .page-sidebar-menu > li.open > a > .arrow:before,
.page-sidebar .page-sidebar-menu > li.open > a > .arrow.open:before {
  color: #606c7d;
}
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu
  > li.active
  > a,
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu
  > li.active.open
  > a,
.page-sidebar .page-sidebar-menu > li.active > a,
.page-sidebar .page-sidebar-menu > li.active.open > a {
  background: #1bbc9b;
  border-top-color: transparent;
  color: #ffffff;
}
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu
  > li.active
  > a:hover,
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu
  > li.active.open
  > a:hover,
.page-sidebar .page-sidebar-menu > li.active > a:hover,
.page-sidebar .page-sidebar-menu > li.active.open > a:hover {
  background: #1bbc9b;
}
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu
  > li.active
  > a
  > i,
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu
  > li.active.open
  > a
  > i,
.page-sidebar .page-sidebar-menu > li.active > a > i,
.page-sidebar .page-sidebar-menu > li.active.open > a > i {
  color: #ffffff;
}
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu
  > li.active
  > a
  > .arrow:before,
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu
  > li.active
  > a
  > .arrow.open:before,
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu
  > li.active.open
  > a
  > .arrow:before,
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu
  > li.active.open
  > a
  > .arrow.open:before,
.page-sidebar .page-sidebar-menu > li.active > a > .arrow:before,
.page-sidebar .page-sidebar-menu > li.active > a > .arrow.open:before,
.page-sidebar .page-sidebar-menu > li.active.open > a > .arrow:before,
.page-sidebar .page-sidebar-menu > li.active.open > a > .arrow.open:before {
  color: #ffffff;
}
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu
  > li.active
  + li
  > a,
.page-sidebar .page-sidebar-menu > li.active + li > a {
  border-top-color: transparent;
}
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu
  > li.active.open
  + li
  > a,
.page-sidebar .page-sidebar-menu > li.active.open + li > a {
  border-top-color: #3d4957;
}
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu
  > li:last-child
  > a,
.page-sidebar .page-sidebar-menu > li:last-child > a {
  border-bottom: 1px solid transparent !important;
}
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu
  li
  > a
  > .arrow:before,
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu
  li
  > a
  > .arrow.open:before,
.page-sidebar .page-sidebar-menu li > a > .arrow:before,
.page-sidebar .page-sidebar-menu li > a > .arrow.open:before {
  color: #606c7d;
}
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu
  li:hover
  > a
  > .arrow:before,
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu
  li:hover
  > a
  > .arrow.open:before,
.page-sidebar .page-sidebar-menu li:hover > a > .arrow:before,
.page-sidebar .page-sidebar-menu li:hover > a > .arrow.open:before {
  color: #606c7d;
}
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu
  li.active
  > a
  > .arrow:before,
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu
  li.active
  > a
  > .arrow.open:before,
.page-sidebar .page-sidebar-menu li.active > a > .arrow:before,
.page-sidebar .page-sidebar-menu li.active > a > .arrow.open:before {
  color: #ffffff;
}
.page-sidebar-closed
  .page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu:hover
  .sub-menu,
.page-sidebar-closed .page-sidebar .page-sidebar-menu:hover .sub-menu {
  background-color: #364150;
}
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu
  .sub-menu
  > li
  > a,
.page-sidebar .page-sidebar-menu .sub-menu > li > a {
  color: #b4bcc8;
}
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu
  .sub-menu
  > li
  > a
  > i,
.page-sidebar .page-sidebar-menu .sub-menu > li > a > i {
  color: #606c7d;
}
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu
  .sub-menu
  > li
  > a
  > i[class^="icon-"],
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu
  .sub-menu
  > li
  > a
  > i[class*="icon-"],
.page-sidebar .page-sidebar-menu .sub-menu > li > a > i[class^="icon-"],
.page-sidebar .page-sidebar-menu .sub-menu > li > a > i[class*="icon-"] {
  color: #6b788b;
}
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu
  .sub-menu
  > li
  > a
  > .arrow:before,
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu
  .sub-menu
  > li
  > a
  > .arrow.open:before,
.page-sidebar .page-sidebar-menu .sub-menu > li > a > .arrow:before,
.page-sidebar .page-sidebar-menu .sub-menu > li > a > .arrow.open:before {
  color: #606c7d;
}
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu
  .sub-menu
  > li:hover
  > a,
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu
  .sub-menu
  > li.open
  > a,
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu
  .sub-menu
  > li.active
  > a,
.page-sidebar .page-sidebar-menu .sub-menu > li:hover > a,
.page-sidebar .page-sidebar-menu .sub-menu > li.open > a,
.page-sidebar .page-sidebar-menu .sub-menu > li.active > a {
  background: #3e4b5c !important;
}
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu
  .sub-menu
  > li:hover
  > a
  > i,
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu
  .sub-menu
  > li.open
  > a
  > i,
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu
  .sub-menu
  > li.active
  > a
  > i,
.page-sidebar .page-sidebar-menu .sub-menu > li:hover > a > i,
.page-sidebar .page-sidebar-menu .sub-menu > li.open > a > i,
.page-sidebar .page-sidebar-menu .sub-menu > li.active > a > i {
  color: #606c7d;
  color: #959fae;
}
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu
  .sub-menu
  > li:hover
  > a
  > .arrow:before,
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu
  .sub-menu
  > li:hover
  > a
  > .arrow.open:before,
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu
  .sub-menu
  > li.open
  > a
  > .arrow:before,
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu
  .sub-menu
  > li.open
  > a
  > .arrow.open:before,
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu
  .sub-menu
  > li.active
  > a
  > .arrow:before,
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu
  .sub-menu
  > li.active
  > a
  > .arrow.open:before,
.page-sidebar .page-sidebar-menu .sub-menu > li:hover > a > .arrow:before,
.page-sidebar .page-sidebar-menu .sub-menu > li:hover > a > .arrow.open:before,
.page-sidebar .page-sidebar-menu .sub-menu > li.open > a > .arrow:before,
.page-sidebar .page-sidebar-menu .sub-menu > li.open > a > .arrow.open:before,
.page-sidebar .page-sidebar-menu .sub-menu > li.active > a > .arrow:before,
.page-sidebar
  .page-sidebar-menu
  .sub-menu
  > li.active
  > a
  > .arrow.open:before {
  color: #606c7d;
}
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu.page-sidebar-menu-light,
.page-sidebar .page-sidebar-menu.page-sidebar-menu-light {
  /* 1st level links */
}
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu.page-sidebar-menu-light
  > li:hover
  > a,
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu.page-sidebar-menu-light
  > li.open
  > a,
.page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li:hover > a,
.page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.open > a {
  background: #3a4656;
}
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu.page-sidebar-menu-light
  > li.active
  > a,
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu.page-sidebar-menu-light
  > li.active.open
  > a,
.page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.active > a,
.page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.active.open > a {
  background: #3e4b5c;
  border-left: 4px solid #3077f0;
  color: #f1f1f1;
}
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu.page-sidebar-menu-light
  > li.active
  > a:hover,
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu.page-sidebar-menu-light
  > li.active.open
  > a:hover,
.page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.active > a:hover,
.page-sidebar
  .page-sidebar-menu.page-sidebar-menu-light
  > li.active.open
  > a:hover {
  border-left: 4px solid #1bbc9b;
  background: #3a4656;
}
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu.page-sidebar-menu-light
  > li.active
  > a
  > i,
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu.page-sidebar-menu-light
  > li.active.open
  > a
  > i,
.page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.active > a > i,
.page-sidebar
  .page-sidebar-menu.page-sidebar-menu-light
  > li.active.open
  > a
  > i {
  color: #eeeeee;
}
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu.page-sidebar-menu-light
  > li.active
  > a
  > .arrow:before,
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu.page-sidebar-menu-light
  > li.active
  > a
  > .arrow.open:before,
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu.page-sidebar-menu-light
  > li.active.open
  > a
  > .arrow:before,
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu.page-sidebar-menu-light
  > li.active.open
  > a
  > .arrow.open:before,
.page-sidebar
  .page-sidebar-menu.page-sidebar-menu-light
  > li.active
  > a
  > .arrow:before,
.page-sidebar
  .page-sidebar-menu.page-sidebar-menu-light
  > li.active
  > a
  > .arrow.open:before,
.page-sidebar
  .page-sidebar-menu.page-sidebar-menu-light
  > li.active.open
  > a
  > .arrow:before,
.page-sidebar
  .page-sidebar-menu.page-sidebar-menu-light
  > li.active.open
  > a
  > .arrow.open:before {
  color: #eeeeee;
}
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu.page-sidebar-menu-light
  > li
  .sub-menu,
.page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li .sub-menu {
  background: #3a4656;
}
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu.page-sidebar-menu-light
  > li
  .sub-menu
  > li:hover
  > a,
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu.page-sidebar-menu-light
  > li
  .sub-menu
  > li.open
  > a,
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .page-sidebar-menu.page-sidebar-menu-light
  > li
  .sub-menu
  > li.active
  > a,
.page-sidebar
  .page-sidebar-menu.page-sidebar-menu-light
  > li
  .sub-menu
  > li:hover
  > a,
.page-sidebar
  .page-sidebar-menu.page-sidebar-menu-light
  > li
  .sub-menu
  > li.open
  > a,
.page-sidebar
  .page-sidebar-menu.page-sidebar-menu-light
  > li
  .sub-menu
  > li.active
  > a {
  background: #3e4b5c !important;
}
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-toggler,
.page-sidebar .sidebar-toggler {
  background: #2c3541;
}
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .sidebar-toggler
  > span,
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .sidebar-toggler
  > span:before,
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .sidebar-toggler
  > span:after,
.page-sidebar .sidebar-toggler > span,
.page-sidebar .sidebar-toggler > span:before,
.page-sidebar .sidebar-toggler > span:after {
  background: #a7b5c6;
}
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .sidebar-toggler
  > span:hover,
.page-sidebar .sidebar-toggler > span:hover {
  background: #a7b5c6;
}
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .sidebar-toggler
  > span:hover:before,
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .sidebar-toggler
  > span:hover:after,
.page-sidebar .sidebar-toggler > span:hover:before,
.page-sidebar .sidebar-toggler > span:hover:after {
  background: #a7b5c6;
}
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .sidebar-toggler.th-toggle-exit
  > span,
.page-sidebar .sidebar-toggler.th-toggle-exit > span {
  background-color: transparent !important;
}
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .sidebar-toggler:hover,
.page-sidebar .sidebar-toggler:hover {
  background: #212832;
}
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .sidebar-search
  .input-group,
.page-sidebar .sidebar-search .input-group {
  border-bottom: 1px solid #435060;
}
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .sidebar-search
  .input-group
  .form-control,
.page-sidebar .sidebar-search .input-group .form-control {
  background-color: #364150;
  color: #4e5d6f;
}
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .sidebar-search
  .input-group
  .form-control::-moz-placeholder,
.page-sidebar .sidebar-search .input-group .form-control::-moz-placeholder {
  color: #4e5d6f;
  opacity: 1;
}
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .sidebar-search
  .input-group
  .form-control:-ms-input-placeholder,
.page-sidebar .sidebar-search .input-group .form-control:-ms-input-placeholder {
  color: #4e5d6f;
}
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .sidebar-search
  .input-group
  .form-control::-webkit-input-placeholder,
.page-sidebar
  .sidebar-search
  .input-group
  .form-control::-webkit-input-placeholder {
  color: #4e5d6f;
}
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .sidebar-search
  .input-group
  .input-group-btn
  .btn
  > i,
.page-sidebar .sidebar-search .input-group .input-group-btn .btn > i {
  color: #4e5d6f;
}
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .sidebar-search.sidebar-search-bordered
  .input-group,
.page-sidebar .sidebar-search.sidebar-search-bordered .input-group {
  border: 1px solid #435060;
}
.page-sidebar-closed
  .page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .sidebar-search.open
  .input-group,
.page-sidebar-closed .page-sidebar .sidebar-search.open .input-group {
  background-color: #364150;
}
.page-sidebar-closed
  .page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .sidebar-search.open
  .remove
  > i,
.page-sidebar-closed .page-sidebar .sidebar-search.open .remove > i {
  color: #4e5d6f;
}
.page-sidebar-closed
  .page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .sidebar-search.sidebar-search-solid
  .input-group,
.page-sidebar-closed
  .page-sidebar
  .sidebar-search.sidebar-search-solid
  .input-group {
  background: none;
}
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .sidebar-search.sidebar-search-solid
  .input-group,
.page-sidebar .sidebar-search.sidebar-search-solid .input-group {
  border: 1px solid #2c3541;
  background: #2c3541;
}
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .sidebar-search.sidebar-search-solid
  .input-group
  .form-control,
.page-sidebar .sidebar-search.sidebar-search-solid .input-group .form-control {
  background: #2c3541;
}
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .sidebar-search.sidebar-search-solid.open
  .input-group,
.page-sidebar .sidebar-search.sidebar-search-solid.open .input-group {
  border: 1px solid #364150;
  background: #364150;
}
.page-sidebar-closed.page-sidebar-fixed
  .page-sidebar:hover
  .sidebar-search.sidebar-search-solid.open
  .input-group
  .form-control,
.page-sidebar
  .sidebar-search.sidebar-search-solid.open
  .input-group
  .form-control {
  background: #364150;
}

.page-sidebar-reversed .page-sidebar-menu.page-sidebar-menu-light {
  /* 1st level links */
}
.page-sidebar-reversed
  .page-sidebar-menu.page-sidebar-menu-light
  > li.active
  > a,
.page-sidebar-reversed
  .page-sidebar-menu.page-sidebar-menu-light
  > li.active.open
  > a {
  border-left: 0;
  border-right: 4px solid #1bbc9b;
}
.page-sidebar-reversed
  .page-sidebar-menu.page-sidebar-menu-light
  > li.active
  > a:hover,
.page-sidebar-reversed
  .page-sidebar-menu.page-sidebar-menu-light
  > li.active.open
  > a:hover {
  border-left: 0;
  border-right: 4px solid #1bbc9b;
}

/******
    Page Footer 
    ******/
.page-footer .page-footer-inner {
  color: #98a6ba;
}

.page-footer-fixed .page-footer {
  background-color: #28303b;
}

@media (min-width: 992px) {
  /* 992px */
  /* Sidebar menu closed */
  .page-sidebar-menu.page-sidebar-menu-hover-submenu > li:hover > .sub-menu {
    box-shadow: 5px 5px rgba(44, 53, 66, 0.2);
  }
  .page-sidebar-menu.page-sidebar-menu-hover-submenu
    > li:hover
    > .sub-menu.sidebar-toggler-wrapper,
  .page-sidebar-menu.page-sidebar-menu-hover-submenu
    > li:hover
    > .sub-menu.sidebar-search-wrapper {
    box-shadow: none;
  }
  .page-sidebar-menu.page-sidebar-menu-closed > li:hover {
    box-shadow: 5px 5px rgba(44, 53, 66, 0.2);
  }
  .page-sidebar-menu.page-sidebar-menu-closed
    > li:hover.sidebar-toggler-wrapper,
  .page-sidebar-menu.page-sidebar-menu-closed
    > li:hover.sidebar-search-wrapper {
    box-shadow: none;
  }
  .page-sidebar-menu.page-sidebar-menu-closed > li:hover > .sub-menu {
    box-shadow: 5px 5px rgba(44, 53, 66, 0.2);
  }
  .page-sidebar-menu.page-sidebar-menu-closed
    > li:hover
    > .sub-menu.sidebar-toggler-wrapper,
  .page-sidebar-menu.page-sidebar-menu-closed
    > li:hover
    > .sub-menu.sidebar-search-wrapper {
    box-shadow: none;
  }
  /* Light sidebar menu */
  .page-sidebar-menu.page-sidebar-menu-light.page-sidebar-menu-closed
    > li.heading {
    padding: 0;
    margin-top: 15px;
    margin-bottom: 15px;
    border-top: 1px solid #3d4957 !important;
  }
  /* Fixed Sidebar */
  .page-sidebar-fixed:not(.page-footer-fixed) .page-content {
    border-bottom: 0;
  }
  .page-sidebar-fixed:not(.page-footer-fixed) .page-footer {
    background-color: #fff;
  }
  .page-sidebar-fixed:not(.page-footer-fixed) .page-footer .page-footer-inner {
    color: #333;
  }
  /* Boxed Layout */
  .page-boxed {
    background-color: #303a47 !important;
    /* Page container */
    /* Page sidebar */
    /* Page footer */
  }
  .page-boxed .page-container {
    background-color: #364150;
    border-left: 1px solid #3d4957;
    border-bottom: 1px solid #3d4957;
  }
  .page-boxed.page-sidebar-reversed .page-container {
    border-left: 0;
    border-right: 1px solid #3d4957;
  }
  .page-boxed.page-sidebar-fixed .page-container {
    border-left: 0;
    border-bottom: 0;
  }
  .page-boxed.page-sidebar-reversed.page-sidebar-fixed .page-container {
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
  }
  .page-boxed.page-sidebar-fixed .page-sidebar {
    border-left: 1px solid #3d4957;
  }
  .page-boxed.page-sidebar-reversed.page-sidebar-fixed .page-sidebar {
    border-right: 1px solid #3d4957;
    border-left: 0;
  }
  .page-boxed.page-sidebar-fixed.page-footer-fixed .page-footer {
    background-color: #303a47 !important;
  }
  .page-boxed.page-sidebar-fixed.page-footer-fixed
    .page-footer
    .page-footer-inner {
    color: #98a6ba;
  }
  /* Sidebar Menu Wirh Hoverable Submenu */
  .page-sidebar-menu-hover-submenu li:hover a > .arrow {
    border-right: 8px solid #323c4b;
  }
  .page-sidebar-reversed .page-sidebar-menu-hover-submenu li:hover a > .arrow {
    border-left: 8px solid #323c4b;
  }
  .page-sidebar-menu-hover-submenu li:hover > .sub-menu {
    background: #323c4b !important;
  }
}

@media (max-width: 991px) {
  /* 991px */
  /* Page sidebar */
  .page-sidebar {
    background-color: #28303b;
    /* light sidebar */
  }
  .page-sidebar .page-sidebar-menu > li > a {
    border-top: 1px solid #364150;
  }
  .page-sidebar .page-sidebar-menu > li:hover > a,
  .page-sidebar .page-sidebar-menu > li.open > a {
    background: #2e3744;
  }
  .page-sidebar .page-sidebar-menu > li:last-child > a {
    border-bottom: 0 !important;
  }
  .page-sidebar .page-sidebar-menu > li .sub-menu {
    background-color: #28303b !important;
  }
  .page-sidebar .page-sidebar-menu .sidebar-search input {
    background-color: #28303b !important;
  }
  .page-sidebar .page-sidebar-menu.page-sidebar-menu-light {
    /* 1st level links */
  }
  .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li:hover > a,
  .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.open > a {
    background: #2e3744;
  }
  .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.active > a,
  .page-sidebar
    .page-sidebar-menu.page-sidebar-menu-light
    > li.active.open
    > a {
    background: #2e3744;
  }
  .page-sidebar
    .page-sidebar-menu.page-sidebar-menu-light
    > li.active
    > a:hover,
  .page-sidebar
    .page-sidebar-menu.page-sidebar-menu-light
    > li.active.open
    > a:hover {
    background: #2e3744;
  }
  .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li .sub-menu {
    background: #28303b !important;
  }
  .page-sidebar
    .page-sidebar-menu.page-sidebar-menu-light
    > li
    .sub-menu
    > li:hover
    > a,
  .page-sidebar
    .page-sidebar-menu.page-sidebar-menu-light
    > li
    .sub-menu
    > li.open
    > a,
  .page-sidebar
    .page-sidebar-menu.page-sidebar-menu-light
    > li
    .sub-menu
    > li.active
    > a {
    background: #2e3744 !important;
  }
}

@media (max-width: 480px) {
  /* 480px */
  .page-header.navbar {
    /* Top menu */
  }
  .page-header.navbar .top-menu {
    background-color: #364150;
  }
  .page-header-fixed-mobile .page-header.navbar .top-menu {
    background-color: #2b3643;
  }
  .page-header.navbar
    .top-menu
    .navbar-nav
    > li.dropdown-user
    .dropdown-toggle {
    background-color: #415265;
  }
  .page-header-fixed-mobile
    .page-header.navbar
    .top-menu
    .navbar-nav
    > li.dropdown-user
    .dropdown-toggle {
    background: none;
  }
  .page-header.navbar
    .top-menu
    .navbar-nav
    > li.dropdown-user
    .dropdown-toggle:hover {
    background-color: #3f4f62;
  }
}

/****
    Boby
    ****/
body {
  background-color: #364150;
}

/****
 CSS3 Spinner Bar
****/
.page-spinner-bar > div,
.block-spinner-bar > div {
  background: #4bccd8;
}
