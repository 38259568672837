.event {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background: #0d6cfe;
    color: white;
    padding: 5px;
  }
.event-time{
  width: 25%;
}  
.event-info{
  width: 75%;
}
.event-title{
  font-size: 14px!important;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.event-client{
  font-size: 14px!important;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.fc-button-active{
  color: #fff!important;
}
.portlet.calendar.light .fc-button{
  padding-top: 10px!important;
  padding-bottom: 15px!important;
}
.fc-prev-button, .fc-prev-button{
  display: none;
}