.claims-table {
  overflow-x: auto;
  overflow-y: auto !important;
}
.claims-table::-webkit-scrollbar {
  width: 0.5em;
}

.claims-table::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #f2f2f2;
  background: #eee;
  border-radius: 0px;
}

.claims-table::-webkit-scrollbar-thumb {
  background-color: rgb(190, 189, 189);
  outline: 1px solid #eee;
}

@media all and (min-width:1191px) and (max-width: 1400px) {
  
}


@media all and (min-width:1024px) and (max-width: 1190px) {
  .claims-table {
    height: 61vh!important;
  }
}

@media all and (min-width:990px) and (max-width: 1023px) {
  .claims-table {
    height: 61vh!important;
  }
}

@media all and (min-width:801px) and (max-width: 950px) {
  .claims-table {
    height: 62vh!important;
  }
}

@media all and (min-width:769px) and (max-width: 800px) {
  .claims-table {
    height: 62vh!important;
  }
}

@media all and (min-width:710px) and (max-width: 768px) {
  .claims-table {
    height: 62vh!important;
  }
}

@media all and (min-width:569px) and (max-width: 709px) {
  .claims-table {
    height: 60vh!important;
  }
}


@media all and (min-width:481px) and (max-width: 568px) {
  .claims-table {
    height: 58vh!important;
  }
}

@media all and (min-width:321px) and (max-width: 480px) {
  .claims-table {
    height: 47vh!important;
  }
}

@media all and (min-width:0px) and (max-width: 320px) {
  .claims-table {
    height: 37vh!important;
  }
}