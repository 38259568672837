.loading-message img {
  display: block !important;
  margin-left: 40px !important;
  height: 20px !important;
}
.dz-remove {
  display: none !important;
}
.mt-repeater .mt-repeater-item {
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
}
.lobibox-notify.rounded {
  border-radius: 0px !important;
}
.lobibox-notify-wrapper {
  z-index: 10052;
}
.lobibox-notify-icon {
  margin-top: 5px;
}
.avatar-assign-adj {
  width: 80px;
  height: 80px;
  background-color: #fff1f3;
  text-align: center;
  border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  display: inline-block;
}
.initials-assign-adj {
  position: relative;
  top: 31px; /* 25% of parent */
  font-size: 26px; /* 50% of parent */
  line-height: 16px; /* 50% of parent */
  color: black;
  text-transform: uppercase;
}
.animation-target {
  -webkit-animation: animation 1000ms linear both;
  animation: animation 1000ms linear both;
}

@-webkit-keyframes animation {
  0% {
    -webkit-transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  4.3% {
    -webkit-transform: matrix3d(
      0.514,
      0,
      0,
      0,
      0,
      0.514,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(0.514, 0, 0, 0, 0, 0.514, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  8.61% {
    -webkit-transform: matrix3d(
      0.879,
      0,
      0,
      0,
      0,
      0.879,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(0.879, 0, 0, 0, 0, 0.879, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  12.91% {
    -webkit-transform: matrix3d(
      1.052,
      0,
      0,
      0,
      0,
      1.052,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(1.052, 0, 0, 0, 0, 1.052, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  17.22% {
    -webkit-transform: matrix3d(
      1.093,
      0,
      0,
      0,
      0,
      1.093,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(1.093, 0, 0, 0, 0, 1.093, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  28.33% {
    -webkit-transform: matrix3d(
      1.021,
      0,
      0,
      0,
      0,
      1.021,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(1.021, 0, 0, 0, 0, 1.021, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  39.44% {
    -webkit-transform: matrix3d(
      0.994,
      0,
      0,
      0,
      0,
      0.994,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(0.994, 0, 0, 0, 0, 0.994, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  61.66% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  83.98% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  100% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}

@keyframes animation {
  0% {
    -webkit-transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  4.3% {
    -webkit-transform: matrix3d(
      0.514,
      0,
      0,
      0,
      0,
      0.514,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(0.514, 0, 0, 0, 0, 0.514, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  8.61% {
    -webkit-transform: matrix3d(
      0.879,
      0,
      0,
      0,
      0,
      0.879,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(0.879, 0, 0, 0, 0, 0.879, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  12.91% {
    -webkit-transform: matrix3d(
      1.052,
      0,
      0,
      0,
      0,
      1.052,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(1.052, 0, 0, 0, 0, 1.052, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  17.22% {
    -webkit-transform: matrix3d(
      1.093,
      0,
      0,
      0,
      0,
      1.093,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(1.093, 0, 0, 0, 0, 1.093, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  28.33% {
    -webkit-transform: matrix3d(
      1.021,
      0,
      0,
      0,
      0,
      1.021,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(1.021, 0, 0, 0, 0, 1.021, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  39.44% {
    -webkit-transform: matrix3d(
      0.994,
      0,
      0,
      0,
      0,
      0.994,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(0.994, 0, 0, 0, 0, 0.994, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  61.66% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  83.98% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  100% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}

/** BEGIN: design for payment tracker table **/

#PT_DIV_1 {
  box-sizing: border-box;
  color: rgb(85, 85, 85);
  display: flex;
  /*    height: 185.875px;*/
  text-align: left;
  text-decoration: none solid rgb(85, 85, 85);
  text-size-adjust: 100%;

  column-rule-color: rgb(85, 85, 85);
  perspective-origin: 570px 92.9375px;
  transform-origin: 570px 92.9375px;
  caret-color: rgb(85, 85, 85);
  border: 0px none rgb(85, 85, 85);
  flex-flow: row wrap;
  font: normal normal 400 normal 16px / 24px Roboto, sans-serif;
  margin: 48px -15px 0px;
  outline: rgb(85, 85, 85) none 0px;
} /*#PT_DIV_1*/

#PT_DIV_1:after {
  box-sizing: border-box;
  color: rgb(85, 85, 85);
  display: block;
  text-align: left;
  text-decoration: none solid rgb(85, 85, 85);
  text-size-adjust: 100%;
  column-rule-color: rgb(85, 85, 85);
  caret-color: rgb(85, 85, 85);
  border: 0px none rgb(85, 85, 85);
  font: normal normal 400 normal 16px / 24px Roboto, sans-serif;
  outline: rgb(85, 85, 85) none 0px;
} /*#PT_DIV_1:after*/

#PT_DIV_1:before {
  box-sizing: border-box;
  color: rgb(85, 85, 85);
  display: block;
  text-align: left;
  text-decoration: none solid rgb(85, 85, 85);
  text-size-adjust: 100%;
  column-rule-color: rgb(85, 85, 85);
  caret-color: rgb(85, 85, 85);
  border: 0px none rgb(85, 85, 85);
  font: normal normal 400 normal 16px / 24px Roboto, sans-serif;
  outline: rgb(85, 85, 85) none 0px;
} /*#PT_DIV_1:before*/

#PT_DIV_2,
#PT_DIV_11 {
  bottom: 0px;
  box-sizing: border-box;
  color: rgb(85, 85, 85);
  /*    height: 89.875px;*/
  left: 0px;
  max-width: 50%;
  min-height: 1px;
  min-width: auto;
  position: relative;
  right: 0px;
  text-align: left;
  text-decoration: none solid rgb(85, 85, 85);
  text-size-adjust: 100%;
  top: 0px;

  column-rule-color: rgb(85, 85, 85);
  perspective-origin: 285px 44.9375px;
  transform-origin: 285px 44.9375px;
  caret-color: rgb(85, 85, 85);
  border: 0px none rgb(85, 85, 85);
  flex: 0 0 50%;
  font: normal normal 400 normal 16px / 24px Roboto, sans-serif;
  margin: 48px 0px;
  outline: rgb(85, 85, 85) none 0px;
  padding: 0px 15px;
} /*#PT_DIV_2, #PT_DIV_11*/

#PT_DIV_2:after,
#PT_DIV_11:after {
  box-sizing: border-box;
  color: rgb(85, 85, 85);
  min-height: auto;
  min-width: auto;
  text-align: left;
  text-decoration: none solid rgb(85, 85, 85);
  text-size-adjust: 100%;
  column-rule-color: rgb(85, 85, 85);
  caret-color: rgb(85, 85, 85);
  border: 0px none rgb(85, 85, 85);
  font: normal normal 400 normal 16px / 24px Roboto, sans-serif;
  outline: rgb(85, 85, 85) none 0px;
} /*#PT_DIV_2:after, #PT_DIV_11:after*/

#PT_DIV_2:before,
#PT_DIV_11:before {
  box-sizing: border-box;
  color: rgb(85, 85, 85);
  min-height: auto;
  min-width: auto;
  text-align: left;
  text-decoration: none solid rgb(85, 85, 85);
  text-size-adjust: 100%;
  column-rule-color: rgb(85, 85, 85);
  caret-color: rgb(85, 85, 85);
  border: 0px none rgb(85, 85, 85);
  font: normal normal 400 normal 16px / 24px Roboto, sans-serif;
  outline: rgb(85, 85, 85) none 0px;
} /*#PT_DIV_2:before, #PT_DIV_11:before*/

#H2_3 {
  box-sizing: border-box;
  color: rgb(46, 204, 113);
  height: 21px;
  text-align: left;
  text-decoration: none solid rgb(46, 204, 113);
  text-shadow: rgba(255, 255, 255, 0.4) 0px 1px 0px;
  text-size-adjust: 100%;
  text-transform: uppercase;

  column-rule-color: rgb(46, 204, 113);
  perspective-origin: 270px 10.5px;
  transform-origin: 270px 10.5px;
  caret-color: rgb(46, 204, 113);
  border: 0px none rgb(46, 204, 113);
  font: normal normal 700 normal 18px / 21.6px Roboto, sans-serif;
  margin: 0px 0px 16.884px;
  outline: rgb(46, 204, 113) none 0px;
} /*#H2_3*/

#H2_3:after {
  box-sizing: border-box;
  color: rgb(46, 204, 113);
  text-align: left;
  text-decoration: none solid rgb(46, 204, 113);
  text-shadow: rgba(255, 255, 255, 0.4) 0px 1px 0px;
  text-size-adjust: 100%;
  text-transform: uppercase;
  column-rule-color: rgb(46, 204, 113);
  caret-color: rgb(46, 204, 113);
  border: 0px none rgb(46, 204, 113);
  font: normal normal 700 normal 18px / 21.6px Roboto, sans-serif;
  outline: rgb(46, 204, 113) none 0px;
} /*#H2_3:after*/

#H2_3:before {
  box-sizing: border-box;
  color: rgb(46, 204, 113);
  text-align: left;
  text-decoration: none solid rgb(46, 204, 113);
  text-shadow: rgba(255, 255, 255, 0.4) 0px 1px 0px;
  text-size-adjust: 100%;
  text-transform: uppercase;
  column-rule-color: rgb(46, 204, 113);
  caret-color: rgb(46, 204, 113);
  border: 0px none rgb(46, 204, 113);
  font: normal normal 700 normal 18px / 21.6px Roboto, sans-serif;
  outline: rgb(46, 204, 113) none 0px;
} /*#H2_3:before*/

#PT_DIV_4,
#PT_DIV_13 {
  box-sizing: border-box;
  color: rgb(85, 85, 85);
  height: 52px;
  max-width: 1140px;
  text-align: left;
  text-decoration: none solid rgb(85, 85, 85);
  text-size-adjust: 100%;

  column-rule-color: rgb(85, 85, 85);
  perspective-origin: 270px 26px;
  transform-origin: 270px 26px;
  caret-color: rgb(85, 85, 85);
  border: 0px none rgb(85, 85, 85);
  font: normal normal 400 normal 16px / 24px Roboto, sans-serif;
  outline: rgb(85, 85, 85) none 0px;
  padding: 0px 15px;
} /*#PT_DIV_4, #PT_DIV_13*/

#PT_DIV_4:after,
#PT_DIV_13:after {
  box-sizing: border-box;
  color: rgb(85, 85, 85);
  text-align: left;
  text-decoration: none solid rgb(85, 85, 85);
  text-size-adjust: 100%;
  column-rule-color: rgb(85, 85, 85);
  caret-color: rgb(85, 85, 85);
  border: 0px none rgb(85, 85, 85);
  font: normal normal 400 normal 16px / 24px Roboto, sans-serif;
  outline: rgb(85, 85, 85) none 0px;
} /*#PT_DIV_4:after, #PT_DIV_13:after*/

#PT_DIV_4:before,
#PT_DIV_13:before {
  box-sizing: border-box;
  color: rgb(85, 85, 85);
  text-align: left;
  text-decoration: none solid rgb(85, 85, 85);
  text-size-adjust: 100%;
  column-rule-color: rgb(85, 85, 85);
  caret-color: rgb(85, 85, 85);
  border: 0px none rgb(85, 85, 85);
  font: normal normal 400 normal 16px / 24px Roboto, sans-serif;
  outline: rgb(85, 85, 85) none 0px;
} /*#PT_DIV_4:before, #PT_DIV_13:before*/

.PT_DIV_5,
.PT_DIV_14 {
  box-sizing: border-box;
  color: rgb(85, 85, 85);
  display: flex;
  height: 52px;
  text-align: left;
  text-decoration: none solid rgb(85, 85, 85);
  text-size-adjust: 100%;

  column-rule-color: rgb(85, 85, 85);
  perspective-origin: 270px 26px;
  transform-origin: 270px 26px;
  caret-color: rgb(85, 85, 85);
  border-top: 1px solid rgb(255, 255, 255);
  border-right: 0px none rgb(85, 85, 85);
  border-bottom: 1px solid rgb(255, 255, 255);
  border-left: 0px none rgb(85, 85, 85);
  flex-flow: row wrap;
  font: normal normal 400 normal 16px / 24px Roboto, sans-serif;
  margin: 0px -15px;
  outline: rgb(85, 85, 85) none 0px;
  padding: 13.008px;
} /*#PT_DIV_5, #PT_DIV_14*/

.PT_DIV_5:after,
.PT_DIV_14:after {
  box-sizing: border-box;
  color: rgb(85, 85, 85);
  display: block;
  text-align: left;
  text-decoration: none solid rgb(85, 85, 85);
  text-size-adjust: 100%;
  column-rule-color: rgb(85, 85, 85);
  caret-color: rgb(85, 85, 85);
  border: 0px none rgb(85, 85, 85);
  font: normal normal 400 normal 16px / 24px Roboto, sans-serif;
  outline: rgb(85, 85, 85) none 0px;
} /*#PT_DIV_5:after, #PT_DIV_14:after*/

.PT_DIV_5:before,
.PT_DIV_14:before {
  box-sizing: border-box;
  color: rgb(85, 85, 85);
  display: block;
  text-align: left;
  text-decoration: none solid rgb(85, 85, 85);
  text-size-adjust: 100%;
  column-rule-color: rgb(85, 85, 85);
  caret-color: rgb(85, 85, 85);
  border: 0px none rgb(85, 85, 85);
  font: normal normal 400 normal 16px / 24px Roboto, sans-serif;
  outline: rgb(85, 85, 85) none 0px;
} /*#PT_DIV_5:before, #PT_DIV_14:before*/

.PT_DIV_6 {
  bottom: 0px;
  box-sizing: border-box;
  color: rgb(85, 85, 85);
  height: 24px;
  left: 0px;
  max-width: 100%;
  min-height: 1px;
  min-width: auto;
  position: relative;
  right: 0px;
  text-align: left;
  text-decoration: none solid rgb(85, 85, 85);
  text-size-adjust: 100%;
  top: 0px;

  column-rule-color: rgb(85, 85, 85);
  perspective-origin: 115.797px 12px;
  transform-origin: 115.797px 12px;
  caret-color: rgb(85, 85, 85);
  border: 0px none rgb(85, 85, 85);
  flex: 1 1 0px;
  font: normal normal 400 normal 16px / 24px Roboto, sans-serif;
  outline: rgb(85, 85, 85) none 0px;
  padding: 0px 15px;
} /*#PT_DIV_6*/

.PT_DIV_6:after {
  box-sizing: border-box;
  color: rgb(85, 85, 85);
  min-height: auto;
  min-width: auto;
  text-align: left;
  text-decoration: none solid rgb(85, 85, 85);
  text-size-adjust: 100%;
  column-rule-color: rgb(85, 85, 85);
  caret-color: rgb(85, 85, 85);
  border: 0px none rgb(85, 85, 85);
  font: normal normal 400 normal 16px / 24px Roboto, sans-serif;
  outline: rgb(85, 85, 85) none 0px;
} /*#PT_DIV_6:after*/

.PT_DIV_6:before {
  box-sizing: border-box;
  color: rgb(85, 85, 85);
  min-height: auto;
  min-width: auto;
  text-align: left;
  text-decoration: none solid rgb(85, 85, 85);
  text-size-adjust: 100%;
  column-rule-color: rgb(85, 85, 85);
  caret-color: rgb(85, 85, 85);
  border: 0px none rgb(85, 85, 85);
  font: normal normal 400 normal 16px / 24px Roboto, sans-serif;
  outline: rgb(85, 85, 85) none 0px;
} /*#PT_DIV_6:before*/

.PT_DIV_7 {
  bottom: 0px;
  box-sizing: border-box;
  color: rgb(46, 204, 113);
  height: 24px;
  left: 0px;
  max-width: 100%;
  min-height: 1px;
  min-width: auto;
  position: relative;
  right: 0px;
  text-align: right;
  text-decoration: none solid rgb(46, 204, 113);
  text-size-adjust: 100%;
  top: 0px;

  column-rule-color: rgb(46, 204, 113);
  perspective-origin: 115.797px 12px;
  transform-origin: 115.797px 12px;
  caret-color: rgb(46, 204, 113);
  border: 0px none rgb(46, 204, 113);
  flex: 1 1 0px;
  font: normal normal 400 normal 16px / 24px Roboto, sans-serif;
  outline: rgb(46, 204, 113) none 0px;
  padding: 0px 15px;
  transition: transform 0.3s ease 0s, -webkit-transform 0.3s ease 0s;
} /*#PT_DIV_7*/

.PT_DIV_7:after {
  box-sizing: border-box;
  color: rgb(46, 204, 113);
  min-height: auto;
  min-width: auto;
  text-align: right;
  text-decoration: none solid rgb(46, 204, 113);
  text-size-adjust: 100%;
  column-rule-color: rgb(46, 204, 113);
  caret-color: rgb(46, 204, 113);
  border: 0px none rgb(46, 204, 113);
  font: normal normal 400 normal 16px / 24px Roboto, sans-serif;
  outline: rgb(46, 204, 113) none 0px;
} /*#PT_DIV_7:after*/

.PT_DIV_7:before {
  box-sizing: border-box;
  color: rgb(46, 204, 113);
  min-height: auto;
  min-width: auto;
  text-align: right;
  text-decoration: none solid rgb(46, 204, 113);
  text-size-adjust: 100%;
  column-rule-color: rgb(46, 204, 113);
  caret-color: rgb(46, 204, 113);
  border: 0px none rgb(46, 204, 113);
  font: normal normal 400 normal 16px / 24px Roboto, sans-serif;
  outline: rgb(46, 204, 113) none 0px;
} /*#PT_DIV_7:before*/

.PT_DIV_8,
.PT_DIV_18 {
  bottom: 0px;
  box-sizing: border-box;
  color: rgb(85, 85, 85);
  height: 24px;
  left: 0px;
  max-width: 8.33333%;
  min-height: 1px;
  min-width: auto;
  position: relative;
  right: 0px;
  text-align: right;
  text-decoration: none solid rgb(85, 85, 85);
  text-size-adjust: 100%;
  top: 0px;

  column-rule-color: rgb(85, 85, 85);
  perspective-origin: 21.4062px 12px;
  transform-origin: 21.4062px 12px;
  caret-color: rgb(85, 85, 85);
  border: 0px none rgb(85, 85, 85);
  /*    flex: 0 0 8.33333%;*/
  font: normal normal 400 normal 16px / 24px Roboto, sans-serif;
  margin: 0px 8px 0px 0px;
  outline: rgb(85, 85, 85) none 0px;
  padding: 0px 15px;
} /*#PT_DIV_8, #PT_DIV_18*/

.PT_DIV_8:after,
.PT_DIV_18:after {
  box-sizing: border-box;
  color: rgb(85, 85, 85);
  min-height: auto;
  min-width: auto;
  text-align: right;
  text-decoration: none solid rgb(85, 85, 85);
  text-size-adjust: 100%;
  column-rule-color: rgb(85, 85, 85);
  caret-color: rgb(85, 85, 85);
  border: 0px none rgb(85, 85, 85);
  font: normal normal 400 normal 16px / 24px Roboto, sans-serif;
  outline: rgb(85, 85, 85) none 0px;
} /*#PT_DIV_8:after, #PT_DIV_18:after*/

.PT_DIV_8:before,
.PT_DIV_18:before {
  box-sizing: border-box;
  color: rgb(85, 85, 85);
  min-height: auto;
  min-width: auto;
  text-align: right;
  text-decoration: none solid rgb(85, 85, 85);
  text-size-adjust: 100%;
  column-rule-color: rgb(85, 85, 85);
  caret-color: rgb(85, 85, 85);
  border: 0px none rgb(85, 85, 85);
  font: normal normal 400 normal 16px / 24px Roboto, sans-serif;
  outline: rgb(85, 85, 85) none 0px;
} /*#PT_DIV_8:before, #PT_DIV_18:before*/

.BUTTON_9 {
  color: rgb(46, 204, 113);
  cursor: pointer;
  display: none;
  text-decoration: none solid rgb(46, 204, 113);
  text-size-adjust: 100%;
  vertical-align: middle;
  white-space: nowrap;
  column-rule-color: rgb(46, 204, 113);
  user-select: none;
  caret-color: rgb(46, 204, 113);
  background: rgba(0, 0, 0, 0) none repeat scroll 0% 0% / auto padding-box
    border-box;
  border: 0px none rgb(46, 204, 113);
  border-radius: 4px 4px 4px 4px;
  font: normal normal 400 normal 12.8px / 12.8px Roboto, sans-serif;
  outline: rgb(46, 204, 113) none 0px;
  padding: 6px 12px;
  transition: color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s,
    border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
} /*#BUTTON_9*/

.BUTTON_9:after {
  box-sizing: border-box;
  color: rgb(46, 204, 113);
  cursor: pointer;
  text-decoration: none solid rgb(46, 204, 113);
  text-size-adjust: 100%;
  white-space: nowrap;
  column-rule-color: rgb(46, 204, 113);
  user-select: none;
  caret-color: rgb(46, 204, 113);
  border: 0px none rgb(46, 204, 113);
  font: normal normal 400 normal 12.8px / 12.8px Roboto, sans-serif;
  outline: rgb(46, 204, 113) none 0px;
} /*#BUTTON_9:after*/

.BUTTON_9:before {
  box-sizing: border-box;
  color: rgb(46, 204, 113);
  cursor: pointer;
  text-decoration: none solid rgb(46, 204, 113);
  text-size-adjust: 100%;
  white-space: nowrap;
  column-rule-color: rgb(46, 204, 113);
  user-select: none;
  caret-color: rgb(46, 204, 113);
  border: 0px none rgb(46, 204, 113);
  font: normal normal 400 normal 12.8px / 12.8px Roboto, sans-serif;
  outline: rgb(46, 204, 113) none 0px;
} /*#BUTTON_9:before*/

.I_10 {
  box-sizing: border-box;
  color: rgb(46, 204, 113);
  cursor: pointer;
  display: inline-block;
  text-align: center;
  text-decoration: none solid rgb(46, 204, 113);
  text-size-adjust: 100%;
  white-space: nowrap;
  column-rule-color: rgb(46, 204, 113);
  user-select: none;
  caret-color: rgb(46, 204, 113);
  border: 0px none rgb(46, 204, 113);
  font: normal normal 900 normal 12.8px / 12.8px "Font Awesome 5 Free";
  outline: rgb(46, 204, 113) none 0px;
} /*#I_10*/

.I_10:after {
  box-sizing: border-box;
  color: rgb(46, 204, 113);
  cursor: pointer;
  text-align: center;
  text-decoration: none solid rgb(46, 204, 113);
  text-size-adjust: 100%;
  white-space: nowrap;
  column-rule-color: rgb(46, 204, 113);
  user-select: none;
  caret-color: rgb(46, 204, 113);
  border: 0px none rgb(46, 204, 113);
  font: normal normal 900 normal 12.8px / 12.8px "Font Awesome 5 Free";
  outline: rgb(46, 204, 113) none 0px;
} /*#I_10:after*/

.I_10:before {
  box-sizing: border-box;
  color: rgb(46, 204, 113);
  content: '"Ã¯â€¡Â¸"';
  cursor: pointer;
  text-align: center;
  text-decoration: none solid rgb(46, 204, 113);
  text-size-adjust: 100%;
  white-space: nowrap;
  column-rule-color: rgb(46, 204, 113);
  user-select: none;
  caret-color: rgb(46, 204, 113);
  border: 0px none rgb(46, 204, 113);
  font: normal normal 900 normal 12.8px / 12.8px "Font Awesome 5 Free";
  outline: rgb(46, 204, 113) none 0px;
} /*#I_10:before*/

#H2_12 {
  box-sizing: border-box;
  color: rgb(231, 76, 60);
  height: 21px;
  text-align: left;
  text-decoration: none solid rgb(231, 76, 60);
  text-shadow: rgba(255, 255, 255, 0.4) 0px 1px 0px;
  text-size-adjust: 100%;
  text-transform: uppercase;

  column-rule-color: rgb(231, 76, 60);
  perspective-origin: 270px 10.5px;
  transform-origin: 270px 10.5px;
  caret-color: rgb(231, 76, 60);
  border: 0px none rgb(231, 76, 60);
  font: normal normal 700 normal 18px / 21.6px Roboto, sans-serif;
  margin: 0px 0px 16.884px;
  outline: rgb(231, 76, 60) none 0px;
} /*#H2_12*/

#H2_12:after {
  box-sizing: border-box;
  color: rgb(231, 76, 60);
  text-align: left;
  text-decoration: none solid rgb(231, 76, 60);
  text-shadow: rgba(255, 255, 255, 0.4) 0px 1px 0px;
  text-size-adjust: 100%;
  text-transform: uppercase;
  column-rule-color: rgb(231, 76, 60);
  caret-color: rgb(231, 76, 60);
  border: 0px none rgb(231, 76, 60);
  font: normal normal 700 normal 18px / 21.6px Roboto, sans-serif;
  outline: rgb(231, 76, 60) none 0px;
} /*#H2_12:after*/

#H2_12:before {
  box-sizing: border-box;
  color: rgb(231, 76, 60);
  text-align: left;
  text-decoration: none solid rgb(231, 76, 60);
  text-shadow: rgba(255, 255, 255, 0.4) 0px 1px 0px;
  text-size-adjust: 100%;
  text-transform: uppercase;
  column-rule-color: rgb(231, 76, 60);
  caret-color: rgb(231, 76, 60);
  border: 0px none rgb(231, 76, 60);
  font: normal normal 700 normal 18px / 21.6px Roboto, sans-serif;
  outline: rgb(231, 76, 60) none 0px;
} /*#H2_12:before*/

.PT_DIV_15 {
  bottom: 0px;
  box-sizing: border-box;
  color: rgb(85, 85, 85);
  height: 24px;
  left: 0px;
  max-width: 100%;
  min-height: 1px;
  min-width: auto;
  position: relative;
  right: 0px;
  text-align: left;
  text-decoration: none solid rgb(85, 85, 85);
  text-size-adjust: 100%;
  top: 0px;

  column-rule-color: rgb(85, 85, 85);
  perspective-origin: 105.078px 12px;
  transform-origin: 105.078px 12px;
  caret-color: rgb(85, 85, 85);
  border: 0px none rgb(85, 85, 85);
  flex: 1 1 0px;
  font: normal normal 400 normal 16px / 24px Roboto, sans-serif;
  outline: rgb(85, 85, 85) none 0px;
  padding: 0px 15px;
} /*#PT_DIV_15*/

.PT_DIV_15:after {
  box-sizing: border-box;
  color: rgb(85, 85, 85);
  min-height: auto;
  min-width: auto;
  text-align: left;
  text-decoration: none solid rgb(85, 85, 85);
  text-size-adjust: 100%;
  column-rule-color: rgb(85, 85, 85);
  caret-color: rgb(85, 85, 85);
  border: 0px none rgb(85, 85, 85);
  font: normal normal 400 normal 16px / 24px Roboto, sans-serif;
  outline: rgb(85, 85, 85) none 0px;
} /*#PT_DIV_15:after*/

.PT_DIV_15:before {
  box-sizing: border-box;
  color: rgb(85, 85, 85);
  min-height: auto;
  min-width: auto;
  text-align: left;
  text-decoration: none solid rgb(85, 85, 85);
  text-size-adjust: 100%;
  column-rule-color: rgb(85, 85, 85);
  caret-color: rgb(85, 85, 85);
  border: 0px none rgb(85, 85, 85);
  font: normal normal 400 normal 16px / 24px Roboto, sans-serif;
  outline: rgb(85, 85, 85) none 0px;
} /*#PT_DIV_15:before*/

.PT_DIV_16 {
  bottom: 0px;
  box-sizing: border-box;
  color: rgb(231, 76, 60);
  height: 24px;
  left: 0px;
  max-width: 100%;
  min-height: 1px;
  min-width: auto;
  position: relative;
  right: 0px;
  text-align: right;
  text-decoration: none solid rgb(231, 76, 60);
  text-size-adjust: 100%;
  top: 0px;

  column-rule-color: rgb(231, 76, 60);
  perspective-origin: 105.078px 12px;
  transform-origin: 105.078px 12px;
  caret-color: rgb(231, 76, 60);
  border: 0px none rgb(231, 76, 60);
  flex: 1 1 0px;
  font: normal normal 400 normal 16px / 24px Roboto, sans-serif;
  outline: rgb(231, 76, 60) none 0px;
  padding: 0px 15px;
  transition: transform 0.3s ease 0s, -webkit-transform 0.3s ease 0s;
} /*#PT_DIV_16*/

.PT_DIV_16:after {
  box-sizing: border-box;
  color: rgb(231, 76, 60);
  min-height: auto;
  min-width: auto;
  text-align: right;
  text-decoration: none solid rgb(231, 76, 60);
  text-size-adjust: 100%;
  column-rule-color: rgb(231, 76, 60);
  caret-color: rgb(231, 76, 60);
  border: 0px none rgb(231, 76, 60);
  font: normal normal 400 normal 16px / 24px Roboto, sans-serif;
  outline: rgb(231, 76, 60) none 0px;
} /*#PT_DIV_16:after*/

.PT_DIV_16:before {
  box-sizing: border-box;
  color: rgb(231, 76, 60);
  min-height: auto;
  min-width: auto;
  text-align: right;
  text-decoration: none solid rgb(231, 76, 60);
  text-size-adjust: 100%;
  column-rule-color: rgb(231, 76, 60);
  caret-color: rgb(231, 76, 60);
  border: 0px none rgb(231, 76, 60);
  font: normal normal 400 normal 16px / 24px Roboto, sans-serif;
  outline: rgb(231, 76, 60) none 0px;
} /*#PT_DIV_16:before*/

.PT_DIV_17 {
  bottom: 0px;
  box-sizing: border-box;
  color: rgb(231, 76, 60);
  height: 24px;
  left: 0px;
  max-width: 8.33333%;
  min-height: 1px;
  min-width: auto;
  position: relative;
  right: 0px;
  text-align: center;
  text-decoration: none solid rgb(231, 76, 60);
  text-size-adjust: 100%;
  top: 0px;

  column-rule-color: rgb(231, 76, 60);
  perspective-origin: 21.4062px 12px;
  transform-origin: 21.4062px 12px;
  caret-color: rgb(231, 76, 60);
  border: 0px none rgb(231, 76, 60);
  border-radius: 25.6px 25.6px 25.6px 25.6px;
  flex: 0 0 8.33333%;
  font: normal normal 400 normal 12.8px / 19.2px Roboto, sans-serif;
  outline: rgb(231, 76, 60) none 0px;
  padding: 0px 15px;
  transition: transform 0.3s ease 0s, -webkit-transform 0.3s ease 0s;
} /*#PT_DIV_17*/

.PT_DIV_17:after {
  box-sizing: border-box;
  color: rgb(231, 76, 60);
  min-height: auto;
  min-width: auto;
  text-align: center;
  text-decoration: none solid rgb(231, 76, 60);
  text-size-adjust: 100%;
  column-rule-color: rgb(231, 76, 60);
  caret-color: rgb(231, 76, 60);
  border: 0px none rgb(231, 76, 60);
  font: normal normal 400 normal 12.8px / 19.2px Roboto, sans-serif;
  outline: rgb(231, 76, 60) none 0px;
} /*#PT_DIV_17:after*/

.PT_DIV_17:before {
  box-sizing: border-box;
  color: rgb(231, 76, 60);
  min-height: auto;
  min-width: auto;
  text-align: center;
  text-decoration: none solid rgb(231, 76, 60);
  text-size-adjust: 100%;
  column-rule-color: rgb(231, 76, 60);
  caret-color: rgb(231, 76, 60);
  border: 0px none rgb(231, 76, 60);
  font: normal normal 400 normal 12.8px / 19.2px Roboto, sans-serif;
  outline: rgb(231, 76, 60) none 0px;
} /*#PT_DIV_17:before*/

.BUTTON_19 {
  color: rgb(231, 76, 60);
  cursor: pointer;
  display: none;
  text-decoration: none solid rgb(231, 76, 60);
  text-size-adjust: 100%;
  vertical-align: middle;
  white-space: nowrap;
  column-rule-color: rgb(231, 76, 60);
  user-select: none;
  caret-color: rgb(231, 76, 60);
  background: rgba(0, 0, 0, 0) none repeat scroll 0% 0% / auto padding-box
    border-box;
  border: 0px none rgb(231, 76, 60);
  border-radius: 4px 4px 4px 4px;
  font: normal normal 400 normal 12.8px / 12.8px Roboto, sans-serif;
  outline: rgb(231, 76, 60) none 0px;
  padding: 6px 12px;
  transition: color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s,
    border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
} /*#BUTTON_19*/

.BUTTON_19:after {
  box-sizing: border-box;
  color: rgb(231, 76, 60);
  cursor: pointer;
  text-decoration: none solid rgb(231, 76, 60);
  text-size-adjust: 100%;
  white-space: nowrap;
  column-rule-color: rgb(231, 76, 60);
  user-select: none;
  caret-color: rgb(231, 76, 60);
  border: 0px none rgb(231, 76, 60);
  font: normal normal 400 normal 12.8px / 12.8px Roboto, sans-serif;
  outline: rgb(231, 76, 60) none 0px;
} /*#BUTTON_19:after*/

.BUTTON_19:before {
  box-sizing: border-box;
  color: rgb(231, 76, 60);
  cursor: pointer;
  text-decoration: none solid rgb(231, 76, 60);
  text-size-adjust: 100%;
  white-space: nowrap;
  column-rule-color: rgb(231, 76, 60);
  user-select: none;
  caret-color: rgb(231, 76, 60);
  border: 0px none rgb(231, 76, 60);
  font: normal normal 400 normal 12.8px / 12.8px Roboto, sans-serif;
  outline: rgb(231, 76, 60) none 0px;
} /*#BUTTON_19:before*/

.I_20 {
  box-sizing: border-box;
  color: rgb(231, 76, 60);
  cursor: pointer;
  display: inline-block;
  text-align: center;
  text-decoration: none solid rgb(231, 76, 60);
  text-size-adjust: 100%;
  white-space: nowrap;
  column-rule-color: rgb(231, 76, 60);
  user-select: none;
  caret-color: rgb(231, 76, 60);
  border: 0px none rgb(231, 76, 60);
  font: normal normal 900 normal 12.8px / 12.8px "Font Awesome 5 Free";
  outline: rgb(231, 76, 60) none 0px;
} /*#I_20*/

.I_20:after {
  box-sizing: border-box;
  color: rgb(231, 76, 60);
  cursor: pointer;
  text-align: center;
  text-decoration: none solid rgb(231, 76, 60);
  text-size-adjust: 100%;
  white-space: nowrap;
  column-rule-color: rgb(231, 76, 60);
  user-select: none;
  caret-color: rgb(231, 76, 60);
  border: 0px none rgb(231, 76, 60);
  font: normal normal 900 normal 12.8px / 12.8px "Font Awesome 5 Free";
  outline: rgb(231, 76, 60) none 0px;
} /*#I_20:after*/

.I_20:before {
  box-sizing: border-box;
  color: rgb(231, 76, 60);
  content: '"Ã¯â€¡Â¸"';
  cursor: pointer;
  text-align: center;
  text-decoration: none solid rgb(231, 76, 60);
  text-size-adjust: 100%;
  white-space: nowrap;
  column-rule-color: rgb(231, 76, 60);
  user-select: none;
  caret-color: rgb(231, 76, 60);
  border: 0px none rgb(231, 76, 60);
  font: normal normal 900 normal 12.8px / 12.8px "Font Awesome 5 Free";
  outline: rgb(231, 76, 60) none 0px;
} /*#I_20:before*/

/** End: design for payment tracker table **/
.jsPanel-title {
  font-size: 2rem !important;
}
div[contenteditable="true"]:focus {
  outline: none;
}
.sweet-alert,
.sweet-overlay {
  z-index: 9999999 !important;
}

.jsPanel {
  z-index: 99999 !important;
}
.updjPanelBox {
  z-index: 999999 !important;
}
.jsPanel-theme-primary {
  background-color: #2b3643 !important;
  border-color: #2b3643 !important;
}
.loader svg path,
.loader svg rect {
  fill: #ff6700;
}
.jsPanel-controlbar {
  padding-right: 2rem !important;
}

.avatar-circle-notes {
  width: 80px;
  height: 80px;
  float: left;
  background-color: #fff1f3;
  text-align: center;
  border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  margin-right: 2px;
}

.initials-notes {
  position: relative;
  top: 31px; /* 25% of parent */
  font-size: 20px; /* 50% of parent */
  line-height: 16px; /* 50% of parent */
  color: black;
  text-transform: uppercase;
}
.timeline-body-content {
  font-weight: 400;
}
.timeline-body-content h1 {
  font-size: 1.7em !important;
  font-weight: 700;
}

/**** verical acroll bar fixes notes dock window ****/
trix-editor::-webkit-scrollbar {
  -webkit-appearance: none;
}

trix-editor::-webkit-scrollbar:vertical {
  width: 11px;
}

trix-editor::-webkit-scrollbar:horizontal {
  height: 11px;
}

trix-editor::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.5);
}

trix-editor::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 8px;
}

.jsPanel-content .page-header {
  display: none !important;
}
.jsPanel-content .page-sidebar-wrapper {
  display: none !important;
}
.jsPanel-content .page-header-fixed .page-container {
  margin-top: 0px !important;
}
.jsPanel-content .page-content-wrapper .page-content {
  margin-left: 0px !important;
}
.row {
  margin-bottom: 10px;
}
.error {
  color: #e73d4a;
}
.submenu_generate {
  display: none;
}
.portlet.light .form .form-body,
.portlet.light .portlet-form .form-body {
  padding-left: 20px;
  padding-right: 20px;
}
.email_client td,
.email_client th {
  padding: 10px;
}

input.inbox_but {
  background-color: #8cd937;
  color: white;
  border: 0px;
  padding: 5px 10px;
  margin-left: 28px;
}
.child ul {
  width: 100%;
}

.avatar-circle {
  width: 60px;
  height: 60px;
  float: left;
  background-color: #fff1f3;
  text-align: center;
  border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  margin-right: 2px;
}

.initials {
  position: relative;
  top: 14px; /* 25% of parent */
  font-size: 16px; /* 50% of parent */
  line-height: 16px; /* 50% of parent */
  color: black;
  text-transform: uppercase;
}

.avatar-circle555 {
  width: 40px;
  height: 40px;
  float: left;
  background-color: #fff1f3;
  text-align: center;
  border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  margin-right: 2px;
}

.initials555 {
  position: relative;
  top: 10px; /* 25% of parent */
  font-size: 16px; /* 50% of parent */
  line-height: 16px; /* 50% of parent */
  color: black;
  text-transform: uppercase;
}

#header_task_bar a {
  color: #484848;
}
#header_task_bar a:hover {
  background-color: none !important;
  background: none !important;
  color: #484848;
}
#header_task_bar:hover {
  background-color: none !important;
}
.tooltip-inner {
  max-width: 220px;
  /* If max-width does not work, try using width instead */
  width: 220px;
}

.sa-confirm-button-container .btn-success,
.sa-confirm-button-container .btn-success:hover,
.sa-confirm-button-container .btn-success:focus {
  background-color: #49a942 !important;
}

.mt-element-ribbon .ribbon-content {
  padding: 0px;
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) {
  #property_apartment_number2,
  #mailing_apartment_number2 {
    margin-left: 0px !important;
  }
}
.numberformat {
  text-align: right !important;
}
.adjusterSharePercentageError {
  border-color: #e73d4a !important;
  color: #e73d4a !important;
}
.displayNone {
  display: none !important;
}
.input-group-addon {
  background-color: #ffffff !important;
}

.futureRemindersCount {
  position: relative !important;
  top: -16px !important;
  right: 13px;
  background-color: #ed6b75;
  color: white;
}
.border-top {
  border-top: 1px solid #afafaf !important;
}
.margin-top-20 {
  margin-top: 20px !important;
}
.modal-custom-footer {
  border-top: 1px transparent;
  padding: 4rem 14rem;
}
.confirm-sendEmail {
  box-shadow: 0px 0.175em 0.5em rgba(2, 8, 20, 0.1),
    0px 0.085em 0.175em rgba(2, 8, 20, 0.08);
  border-radius: 4px !important;
  padding: 2rem;
}

.hideMailingAddress {
  display: none;
}
.page-breadcrumb > li > i.fa-circle {
  margin: 0px !important;
}
.page-sidebar-closed .page-bar {
  width: 95% !important;
}
@media all and (min-width: 1225px) and (max-width: 4000px) {
  /* .page-bar{
        width: 84%!important;
    } */
  /* put your css styles in here */
}
@media all and (min-width: 960px) and (max-width: 1024px) {
  /* put your css styles in here */
}

@media all and (min-width: 801px) and (max-width: 959px) {
  /* put your css styles in here */
}

@media all and (min-width: 769px) and (max-width: 800px) {
  .page-bar {
    width: 100% !important;
    position: initial !important;
  }
}

@media all and (min-width: 569px) and (max-width: 768px) {
  .page-bar {
    width: 100% !important;
    position: initial !important;
  }
}

@media all and (min-width: 481px) and (max-width: 568px) {
  .page-bar {
    width: 100% !important;
    position: initial !important;
  }
}

@media all and (min-width: 321px) and (max-width: 480px) {
  .page-bar {
    width: 100% !important;
    position: initial !important;
  }
}

@media all and (min-width: 0px) and (max-width: 320px) {
  .page-bar {
    width: 100% !important;
    position: initial !important;
  }
}
.cbp-item {
  min-width: 200px !important;
}
.cbp-popup-wrap {
  z-index: 999999 !important;
}
.blockOverlay {
  background-color: rgba(0, 0, 0, 0.36) !important;
}
.spinner {
  content: "";
  position: absolute;
  border: 1px solid #ffffff;
  width: 34px;
  height: 34px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  -webkit-animation: cbp-rotation 0.8s infinite linear;
  animation: cbp-rotation 0.8s infinite linear;
  border-left: 3px solid rgba(114, 144, 182, 0.15);
  border-right: 3px solid rgba(114, 144, 182, 0.15);
  border-bottom: 3px solid rgba(114, 144, 182, 0.15);
  border-top: 3px solid rgba(114, 144, 182, 0.8);
  border-radius: 34px !important;
}

.gallery-loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border: 16px solid #f3f3f3;
  border-radius: 50% !important;
  border-top: 16px solid #4762e2;
  width: 120px !important;
  height: 120px !important;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.gallery > .gallery-item {
  margin: 8px;
  width: 251px;
  height: auto;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
}

.gallery-item img {
  width: 250px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 4px 4px 0 0;
}

.gallery-item img:active {
  outline: 0;
}

.desc {
  padding: 15px;
  text-align: left !important;
  word-break: break-all !important;
}

/* The Modal (background) */
.gallery-slides-modal {
  display: none;
  position: fixed;
  z-index: 9995;
  padding-top: 50px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: black;
}

/* Modal Content */
.gallery-slides-modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  width: 80%;
  max-width: 1200px;
}

#current {
  max-height: 90vh;
}

/* The Close Button */
.closeGallary {
  color: white;
  position: absolute;
  top: 10px;
  right: 25px;
  font-size: 35px;
  font-weight: bold;
}

.closeGallary:hover,
.closeGallary:focus {
  color: #999;
  text-decoration: none;
  cursor: pointer;
}

.cursor {
  cursor: pointer;
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  -webkit-user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}
#caption {
  color: white !important;
}
.caption-container {
  text-align: center;
  background-color: black;
  padding: 2px 16px;
  color: white;
}

img.hover-shadow {
  transition: 0.3s;
}

.hover-shadow:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 4px;
}
.gallery-slide-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background: black;
}
.gallery-slide-wrapper > img {
  width: 100% !important;
}
#allClaimsActivities .desc .font-dark {
  text-align: left !important;
}
.form-control-wrapper .form-control:focus,
.floating-label,
.form-control-wrapper .form-control:not(.empty),
.floating-label,
.form-control-wrapper .form-control:-webkit-autofill,
.floating-label {
  top: -10px;
  font-size: 10px;
  opacity: 1;
}
.jsPanel-content .page-header {
  display: none !important;
}
.jsPanel-content .page-sidebar-wrapper {
  display: none !important;
}
.jsPanel-content .page-header-fixed .page-container {
  margin-top: 0px !important;
}
.jsPanel-content .page-content-wrapper .page-content {
  margin-left: 0px !important;
}
/* .btn.red{
    width: 100%!important;
} */
