.drp-calendar th.prev,
th.next {
  margin-top: -1px;
  top: 21px;
  content: "\276F";
  color: black;
  margin-right: 16px;
}
.drp-calendar th.prev {
  margin-top: 5px;
  transform: rotate(180deg);
}

.drp-calendar th.prev::before {
  content: "\276F";
}

.drp-calendar th.next::before {
  content: "\276F";
}
