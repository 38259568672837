/* TEXT ALIGN */
.tr {
  text-align: right !important;
}
.tl {
  text-align: left !important;
}
.tc {
  text-align: center !important;
}

/* DISPLAY */
.d-block {
  display: block;
}

.m--10 {
  margin-top: -10px !important;
}

/* FLOAT */
.fr {
  float: right !important;
}
.fl {
  float: left !important;
}

/* PADDING ALL */
.p-0 {
  padding: 0px !important;
}
.p-5 {
  padding: 5px !important;
}
.p-10 {
  padding: 10px !important;
}
.p-15 {
  padding: 15px !important;
}
.p-20 {
  padding: 20px !important;
}
.p-25 {
  padding: 25px !important;
}
.p-30 {
  padding: 30px !important;
}
.p-35 {
  padding: 35px !important;
}
.p-40 {
  padding: 40px !important;
}
.p-45 {
  padding: 45px !important;
}
.p-50 {
  padding: 50px !important;
}
.p-55 {
  padding: 55px !important;
}
.p-60 {
  padding: 60px !important;
}
.p-65 {
  padding: 65px !important;
}
.p-70 {
  padding: 70px !important;
}
.p-75 {
  padding: 75px !important;
}
.p-80 {
  padding: 80px !important;
}
.p-85 {
  padding: 85px !important;
}
.p-90 {
  padding: 90px !important;
}
.p-95 {
  padding: 95px !important;
}
.p-100 {
  padding: 100px !important;
}
.p-105 {
  padding: 105px !important;
}
.p-110 {
  padding: 110px !important;
}
.p-115 {
  padding: 115px !important;
}
.p-120 {
  padding: 120px !important;
}
.p-125 {
  padding: 125px !important;
}
.p-130 {
  padding: 130px !important;
}
.p-135 {
  padding: 135px !important;
}
.p-140 {
  padding: 140px !important;
}
.p-145 {
  padding: 145px !important;
}
.p-150 {
  padding: 150px !important;
}
.p-155 {
  padding: 155px !important;
}
/* PADDING TOP */
.pt-0 {
  padding-top: 0px !important;
}
.pt-5 {
  padding-top: 5px !important;
}
.pt-10 {
  padding-top: 10px !important;
}
.pt-15 {
  padding-top: 15px !important;
}
.pt-20 {
  padding-top: 20px !important;
}
.pt-25 {
  padding-top: 25px !important;
}
.pt-30 {
  padding-top: 30px !important;
}
.pt-35 {
  padding-top: 35px !important;
}
.pt-40 {
  padding-top: 40px !important;
}
.pt-45 {
  padding-top: 45px !important;
}
.pt-50 {
  padding-top: 50px !important;
}
.pt-55 {
  padding-top: 55px !important;
}
.pt-60 {
  padding-top: 60px !important;
}
.pt-65 {
  padding-top: 65px !important;
}
.pt-70 {
  padding-top: 70px !important;
}
.pt-75 {
  padding-top: 75px !important;
}
.pt-80 {
  padding-top: 80px !important;
}
.pt-85 {
  padding-top: 85px !important;
}
.pt-90 {
  padding-top: 90px !important;
}
.pt-95 {
  padding-top: 95px !important;
}
.pt-100 {
  padding-top: 100px !important;
}
.pt-105 {
  padding-top: 105px !important;
}
.pt-110 {
  padding-top: 110px !important;
}
.pt-115 {
  padding-top: 115px !important;
}
.pt-120 {
  padding-top: 120px !important;
}
.pt-125 {
  padding-top: 125px !important;
}
.pt-130 {
  padding-top: 130px !important;
}
.pt-135 {
  padding-top: 135px !important;
}
.pt-140 {
  padding-top: 140px !important;
}
.pt-145 {
  padding-top: 145px !important;
}
.pt-150 {
  padding-top: 150px !important;
}
.pt-155 {
  padding-top: 155px !important;
}

/* PADDING HORIZONTAL */
.px-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.px-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.px-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.px-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}
.px-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.px-25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}
.px-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}
.px-35 {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}
.px-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}
.px-45 {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
}
.px-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}
.px-55 {
  padding-top: 55px !important;
  padding-bottom: 55px !important;
}
.px-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}
.px-65 {
  padding-top: 65px !important;
  padding-bottom: 65px !important;
}
.px-70 {
  padding-top: 70px !important;
  padding-bottom: 70px !important;
}
.px-75 {
  padding-top: 75px !important;
  padding-bottom: 75px !important;
}
.px-80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}
.px-85 {
  padding-top: 85px !important;
  padding-bottom: 85px !important;
}
.px-90 {
  padding-top: 90px !important;
  padding-bottom: 90px !important;
}
.px-95 {
  padding-top: 95px !important;
  padding-bottom: 95px !important;
}
.px-100 {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}
.px-105 {
  padding-top: 105px !important;
  padding-bottom: 105px !important;
}
.px-110 {
  padding-top: 110px !important;
  padding-bottom: 110px !important;
}
.px-115 {
  padding-top: 115px !important;
  padding-bottom: 115px !important;
}
.px-120 {
  padding-top: 120px !important;
  padding-bottom: 120px !important;
}
.px-125 {
  padding-top: 125px !important;
  padding-bottom: 125px !important;
}
.px-130 {
  padding-top: 130px !important;
  padding-bottom: 130px !important;
}
.px-135 {
  padding-top: 135px !important;
  padding-bottom: 135px !important;
}
.px-140 {
  padding-top: 140px !important;
  padding-bottom: 140px !important;
}
.px-145 {
  padding-top: 145px !important;
  padding-bottom: 145px !important;
}
.px-150 {
  padding-top: 150px !important;
  padding-bottom: 150px !important;
}
.px-155 {
  padding-top: 155px !important;
  padding-bottom: 155px !important;
}

/* PADDING VERTICAL */
.py-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.py-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.py-15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.py-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.py-25 {
  padding-left: 25px !important;
  padding-right: 25px !important;
}
.py-30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}
.py-35 {
  padding-left: 35px !important;
  padding-right: 35px !important;
}
.py-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}
.py-45 {
  padding-left: 45px !important;
  padding-right: 45px !important;
}
.py-50 {
  padding-left: 50px !important;
  padding-right: 50px !important;
}
.py-55 {
  padding-left: 55px !important;
  padding-right: 55px !important;
}
.py-60 {
  padding-left: 60px !important;
  padding-right: 60px !important;
}
.py-65 {
  padding-left: 65px !important;
  padding-right: 65px !important;
}
.py-70 {
  padding-left: 70px !important;
  padding-right: 70px !important;
}
.py-75 {
  padding-left: 75px !important;
  padding-right: 75px !important;
}
.py-80 {
  padding-left: 80px !important;
  padding-right: 80px !important;
}
.py-85 {
  padding-left: 85px !important;
  padding-right: 85px !important;
}
.py-90 {
  padding-left: 90px !important;
  padding-right: 90px !important;
}
.py-95 {
  padding-left: 95px !important;
  padding-right: 95px !important;
}
.py-100 {
  padding-left: 100px !important;
  padding-right: 100px !important;
}
.py-105 {
  padding-left: 105px !important;
  padding-right: 105px !important;
}
.py-110 {
  padding-left: 110px !important;
  padding-right: 110px !important;
}
.py-115 {
  padding-left: 115px !important;
  padding-right: 115px !important;
}
.py-120 {
  padding-left: 120px !important;
  padding-right: 120px !important;
}
.py-125 {
  padding-left: 125px !important;
  padding-right: 125px !important;
}
.py-130 {
  padding-left: 130px !important;
  padding-right: 130px !important;
}
.py-135 {
  padding-left: 135px !important;
  padding-right: 135px !important;
}
.py-140 {
  padding-left: 140px !important;
  padding-right: 140px !important;
}
.py-145 {
  padding-left: 145px !important;
  padding-right: 145px !important;
}
.py-150 {
  padding-left: 150px !important;
  padding-right: 150px !important;
}
.py-155 {
  padding-left: 155px !important;
  padding-right: 155px !important;
}

/* PADDING BOTTOM */
.pb-0 {
  padding-bottom: 0px !important;
}
.pb-5 {
  padding-bottom: 5px !important;
}
.pb-10 {
  padding-bottom: 10px !important;
}
.pb-15 {
  padding-bottom: 15px !important;
}
.pb-20 {
  padding-bottom: 20px !important;
}
.pb-25 {
  padding-bottom: 25px !important;
}
.pb-30 {
  padding-bottom: 30px !important;
}
.pb-35 {
  padding-bottom: 35px !important;
}
.pb-40 {
  padding-bottom: 40px !important;
}
.pb-45 {
  padding-bottom: 45px !important;
}
.pb-50 {
  padding-bottom: 50px !important;
}
.pb-55 {
  padding-bottom: 55px !important;
}
.pb-60 {
  padding-bottom: 60px !important;
}
.pb-65 {
  padding-bottom: 65px !important;
}
.pb-70 {
  padding-bottom: 70px !important;
}
.pb-75 {
  padding-bottom: 75px !important;
}
.pb-80 {
  padding-bottom: 80px !important;
}
.pb-85 {
  padding-bottom: 85px !important;
}
.pb-90 {
  padding-bottom: 90px !important;
}
.pb-95 {
  padding-bottom: 95px !important;
}
.pb-100 {
  padding-bottom: 100px !important;
}
.pb-105 {
  padding-bottom: 105px !important;
}
.pb-110 {
  padding-bottom: 110px !important;
}
.pb-115 {
  padding-bottom: 115px !important;
}
.pb-120 {
  padding-bottom: 120px !important;
}
.pb-125 {
  padding-bottom: 125px !important;
}
.pb-130 {
  padding-bottom: 130px !important;
}
.pb-135 {
  padding-bottom: 135px !important;
}
.pb-140 {
  padding-bottom: 140px !important;
}
.pb-145 {
  padding-bottom: 145px !important;
}
.pb-150 {
  padding-bottom: 150px !important;
}
.pb-155 {
  padding-bottom: 155px !important;
}

/* PADDING LEFT */
.pl-0 {
  padding-left: 0px !important;
}
.pl-5 {
  padding-left: 5px !important;
}
.pl-10 {
  padding-left: 10px !important;
}
.pl-15 {
  padding-left: 15px !important;
}
.pl-20 {
  padding-left: 20px !important;
}
.pl-25 {
  padding-left: 25px !important;
}
.pl-30 {
  padding-left: 30px !important;
}
.pl-35 {
  padding-left: 35px !important;
}
.pl-40 {
  padding-left: 40px !important;
}
.pl-45 {
  padding-left: 45px !important;
}
.pl-50 {
  padding-left: 50px !important;
}
.pl-55 {
  padding-left: 55px !important;
}
.pl-60 {
  padding-left: 60px !important;
}
.pl-65 {
  padding-left: 65px !important;
}
.pl-70 {
  padding-left: 70px !important;
}
.pl-75 {
  padding-left: 75px !important;
}
.pl-80 {
  padding-left: 80px !important;
}
.pl-85 {
  padding-left: 85px !important;
}
.pl-90 {
  padding-left: 90px !important;
}
.pl-95 {
  padding-left: 95px !important;
}
.pl-100 {
  padding-left: 100px !important;
}
.pl-105 {
  padding-left: 105px !important;
}
.pl-110 {
  padding-left: 110px !important;
}
.pl-115 {
  padding-left: 115px !important;
}
.pl-120 {
  padding-left: 120px !important;
}
.pl-125 {
  padding-left: 125px !important;
}
.pl-130 {
  padding-left: 130px !important;
}
.pl-135 {
  padding-left: 135px !important;
}
.pl-140 {
  padding-left: 140px !important;
}
.pl-145 {
  padding-left: 145px !important;
}
.pl-150 {
  padding-left: 150px !important;
}
.pl-155 {
  padding-left: 155px !important;
}

/* PADDING RIGHT */
.pr-0 {
  padding-right: 0px !important;
}
.pr-5 {
  padding-right: 5px !important;
}
.pr-10 {
  padding-right: 10px !important;
}
.pr-15 {
  padding-right: 15px !important;
}
.pr-20 {
  padding-right: 20px !important;
}
.pr-25 {
  padding-right: 25px !important;
}
.pr-30 {
  padding-right: 30px !important;
}
.pr-35 {
  padding-right: 35px !important;
}
.pr-40 {
  padding-right: 40px !important;
}
.pr-45 {
  padding-right: 45px !important;
}
.pr-50 {
  padding-right: 50px !important;
}
.pr-55 {
  padding-right: 55px !important;
}
.pr-60 {
  padding-right: 60px !important;
}
.pr-65 {
  padding-right: 65px !important;
}
.pr-70 {
  padding-right: 70px !important;
}
.pr-75 {
  padding-right: 75px !important;
}
.pr-80 {
  padding-right: 80px !important;
}
.pr-85 {
  padding-right: 85px !important;
}
.pr-90 {
  padding-right: 90px !important;
}
.pr-95 {
  padding-right: 95px !important;
}
.pr-100 {
  padding-right: 100px !important;
}
.pr-105 {
  padding-right: 105px !important;
}
.pr-110 {
  padding-right: 110px !important;
}
.pr-115 {
  padding-right: 115px !important;
}
.pr-120 {
  padding-right: 120px !important;
}
.pr-125 {
  padding-right: 125px !important;
}
.pr-130 {
  padding-right: 130px !important;
}
.pr-135 {
  padding-right: 135px !important;
}
.pr-140 {
  padding-right: 140px !important;
}
.pr-145 {
  padding-right: 145px !important;
}
.pr-150 {
  padding-right: 150px !important;
}
.pr-155 {
  padding-right: 155px !important;
}

/* MARGIN ALL */
.m-0 {
  margin: 0px !important;
}
.m-5 {
  margin: 5px !important;
}
.m-10 {
  margin: 10px !important;
}
.m-15 {
  margin: 15px !important;
}
.m-20 {
  margin: 20px !important;
}
.m-25 {
  margin: 25px !important;
}
.m-30 {
  margin: 30px !important;
}
.m-35 {
  margin: 35px !important;
}
.m-40 {
  margin: 40px !important;
}
.m-45 {
  margin: 45px !important;
}
.m-50 {
  margin: 50px !important;
}
.m-55 {
  margin: 55px !important;
}
.m-60 {
  margin: 60px !important;
}
.m-65 {
  margin: 65px !important;
}
.m-70 {
  margin: 70px !important;
}
.m-75 {
  margin: 75px !important;
}
.m-80 {
  margin: 80px !important;
}
.m-85 {
  margin: 85px !important;
}
.m-90 {
  margin: 90px !important;
}
.m-95 {
  margin: 95px !important;
}
.m-100 {
  margin: 100px !important;
}
.m-105 {
  margin: 105px !important;
}
.m-110 {
  margin: 110px !important;
}
.m-115 {
  margin: 115px !important;
}
.m-120 {
  margin: 120px !important;
}
.m-125 {
  margin: 125px !important;
}
.m-130 {
  margin: 130px !important;
}
.m-135 {
  margin: 135px !important;
}
.m-140 {
  margin: 140px !important;
}
.m-145 {
  margin: 145px !important;
}
.m-150 {
  margin: 150px !important;
}
.m-155 {
  margin: 155px !important;
}
/* MARGIN TOP */
.mt-0 {
  margin-top: 0px !important;
}
.mt-5 {
  margin-top: 5px !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.mt-15 {
  margin-top: 15px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-25 {
  margin-top: 25px !important;
}
.mt-30 {
  margin-top: 30px !important;
}
.mt-35 {
  margin-top: 35px !important;
}
.mt-40 {
  margin-top: 40px !important;
}
.mt-45 {
  margin-top: 45px !important;
}
.mt-50 {
  margin-top: 50px !important;
}
.mt-55 {
  margin-top: 55px !important;
}
.mt-60 {
  margin-top: 60px !important;
}
.mt-65 {
  margin-top: 65px !important;
}
.mt-70 {
  margin-top: 70px !important;
}
.mt-75 {
  margin-top: 75px !important;
}
.mt-80 {
  margin-top: 80px !important;
}
.mt-85 {
  margin-top: 85px !important;
}
.mt-90 {
  margin-top: 90px !important;
}
.mt-95 {
  margin-top: 95px !important;
}
.mt-100 {
  margin-top: 100px !important;
}
.mt-105 {
  margin-top: 105px !important;
}
.mt-110 {
  margin-top: 110px !important;
}
.mt-115 {
  margin-top: 115px !important;
}
.mt-120 {
  margin-top: 120px !important;
}
.mt-125 {
  margin-top: 125px !important;
}
.mt-130 {
  margin-top: 130px !important;
}
.mt-135 {
  margin-top: 135px !important;
}
.mt-140 {
  margin-top: 140px !important;
}
.mt-145 {
  margin-top: 145px !important;
}
.mt-150 {
  margin-top: 150px !important;
}
.mt-155 {
  margin-top: 155px !important;
}

/* MARGIN BOTTOM */
.mb-0 {
  margin-bottom: 0px !important;
}
.mb-5 {
  margin-bottom: 5px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mb-15 {
  margin-bottom: 15px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-25 {
  margin-bottom: 25px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.mb-35 {
  margin-bottom: 35px !important;
}
.mb-40 {
  margin-bottom: 40px !important;
}
.mb-45 {
  margin-bottom: 45px !important;
}
.mb-50 {
  margin-bottom: 50px !important;
}
.mb-55 {
  margin-bottom: 55px !important;
}
.mb-60 {
  margin-bottom: 60px !important;
}
.mb-65 {
  margin-bottom: 65px !important;
}
.mb-70 {
  margin-bottom: 70px !important;
}
.mb-75 {
  margin-bottom: 75px !important;
}
.mb-80 {
  margin-bottom: 80px !important;
}
.mb-85 {
  margin-bottom: 85px !important;
}
.mb-90 {
  margin-bottom: 90px !important;
}
.mb-95 {
  margin-bottom: 95px !important;
}
.mb-100 {
  margin-bottom: 100px !important;
}
.mb-105 {
  margin-bottom: 105px !important;
}
.mb-110 {
  margin-bottom: 110px !important;
}
.mb-115 {
  margin-bottom: 115px !important;
}
.mb-120 {
  margin-bottom: 120px !important;
}
.mb-125 {
  margin-bottom: 125px !important;
}
.mb-130 {
  margin-bottom: 130px !important;
}
.mb-135 {
  margin-bottom: 135px !important;
}
.mb-140 {
  margin-bottom: 140px !important;
}
.mb-145 {
  margin-bottom: 145px !important;
}
.mb-150 {
  margin-bottom: 150px !important;
}
.mb-155 {
  margin-bottom: 155px !important;
}

/* MARGIN LEFT */
.ml-0 {
  margin-left: 0px !important;
}
.ml-5 {
  margin-left: 5px !important;
}
.ml-10 {
  margin-left: 10px !important;
}
.ml-15 {
  margin-left: 15px !important;
}
.ml-20 {
  margin-left: 20px !important;
}
.ml-25 {
  margin-left: 25px !important;
}
.ml-30 {
  margin-left: 30px !important;
}
.ml-35 {
  margin-left: 35px !important;
}
.ml-40 {
  margin-left: 40px !important;
}
.ml-45 {
  margin-left: 45px !important;
}
.ml-50 {
  margin-left: 50px !important;
}
.ml-55 {
  margin-left: 55px !important;
}
.ml-60 {
  margin-left: 60px !important;
}
.ml-65 {
  margin-left: 65px !important;
}
.ml-70 {
  margin-left: 70px !important;
}
.ml-75 {
  margin-left: 75px !important;
}
.ml-80 {
  margin-left: 80px !important;
}
.ml-85 {
  margin-left: 85px !important;
}
.ml-90 {
  margin-left: 90px !important;
}
.ml-95 {
  margin-left: 95px !important;
}
.ml-100 {
  margin-left: 100px !important;
}
.ml-105 {
  margin-left: 105px !important;
}
.ml-110 {
  margin-left: 110px !important;
}
.ml-115 {
  margin-left: 115px !important;
}
.ml-120 {
  margin-left: 120px !important;
}
.ml-125 {
  margin-left: 125px !important;
}
.ml-130 {
  margin-left: 130px !important;
}
.ml-135 {
  margin-left: 135px !important;
}
.ml-140 {
  margin-left: 140px !important;
}
.ml-145 {
  margin-left: 145px !important;
}
.ml-150 {
  margin-left: 150px !important;
}
.ml-155 {
  margin-left: 155px !important;
}

/* MARGIN RIGHT */
.mr-0 {
  margin-right: 0px !important;
}
.mr-5 {
  margin-right: 5px !important;
}
.mr-10 {
  margin-right: 10px !important;
}
.mr-15 {
  margin-right: 15px !important;
}
.mr-20 {
  margin-right: 20px !important;
}
.mr-25 {
  margin-right: 25px !important;
}
.mr-30 {
  margin-right: 30px !important;
}
.mr-35 {
  margin-right: 35px !important;
}
.mr-40 {
  margin-right: 40px !important;
}
.mr-45 {
  margin-right: 45px !important;
}
.mr-50 {
  margin-right: 50px !important;
}
.mr-55 {
  margin-right: 55px !important;
}
.mr-60 {
  margin-right: 60px !important;
}
.mr-65 {
  margin-right: 65px !important;
}
.mr-70 {
  margin-right: 70px !important;
}
.mr-75 {
  margin-right: 75px !important;
}
.mr-80 {
  margin-right: 80px !important;
}
.mr-85 {
  margin-right: 85px !important;
}
.mr-90 {
  margin-right: 90px !important;
}
.mr-95 {
  margin-right: 95px !important;
}
.mr-100 {
  margin-right: 100px !important;
}
.mr-105 {
  margin-right: 105px !important;
}
.mr-110 {
  margin-right: 110px !important;
}
.mr-115 {
  margin-right: 115px !important;
}
.mr-120 {
  margin-right: 120px !important;
}
.mr-125 {
  margin-right: 125px !important;
}
.mr-130 {
  margin-right: 130px !important;
}
.mr-135 {
  margin-right: 135px !important;
}
.mr-140 {
  margin-right: 140px !important;
}
.mr-145 {
  margin-right: 145px !important;
}
.mr-150 {
  margin-right: 150px !important;
}
.mr-155 {
  margin-right: 155px !important;
}
/*
BORDERS
*/
/* BORDER ALL */
.b-0 {
  border: 0px !important;
}
.b-1 {
  border: 1px solid #eee !important;
}
.b-2 {
  border: 2px solid #e7ecf1 !important;
}
.b-3 {
  border: 3px solid #e7ecf1 !important;
}
/* BORDER TOP */
.bt-0 {
  border-top: 0px solid #e7ecf1 !important;
}
.bt-1 {
  border-top: 1px solid #e7ecf1 !important;
}
.bt-2 {
  border-top: 2px solid #e7ecf1 !important;
}
.bt-3 {
  border-top: 3px solid #e7ecf1 !important;
}
.bt-4 {
  border-top: 4px solid #e7ecf1 !important;
}

/* BORDER BOTTOM */
.bb-0 {
  border-bottom: 0px solid #e7ecf1 !important;
}
.bb-1 {
  border-bottom: 1px solid #e7ecf1 !important;
}
.bb-2 {
  border-bottom: 2px solid #e7ecf1 !important;
}
.bb-3 {
  border-bottom: 3px solid #e7ecf1 !important;
}
.bb-4 {
  border-bottom: 4px solid #e7ecf1 !important;
}

/* BORDER LEFT */
.bl-0 {
  border-left: 0px solid #e7ecf1 !important;
}
.bl-1 {
  border-left: 1px solid #e7ecf1 !important;
}
.bl-2 {
  border-left: 2px solid #e7ecf1 !important;
}
.bl-3 {
  border-left: 3px solid #e7ecf1 !important;
}
.bl-4 {
  border-left: 4px solid #e7ecf1 !important;
}

/* BORDER RIGHT */
.br-0 {
  border-right: 0px solid #e7ecf1 !important;
}
.br-1 {
  border-right: 1px solid #e7ecf1 !important;
}
.br-2 {
  border-right: 2px solid #e7ecf1 !important;
}
.br-3 {
  border-right: 3px solid #e7ecf1 !important;
}
.br-4 {
  border-right: 4px solid #e7ecf1 !important;
}

/* BORDER RADIUS */
.br-1 {
  border-radius: 1px !important;
}
.br-2 {
  border-radius: 2px !important;
}
.br-3 {
  border-radius: 3px !important;
}
.br-4 {
  border-radius: 4px !important;
}
.br-5 {
  border-radius: 5px !important;
}
.br-6 {
  border-radius: 6px !important;
}
.br-7 {
  border-radius: 7px !important;
}
.br-8 {
  border-radius: 8px !important;
}
.br-9 {
  border-radius: 9px !important;
}
.br-10 {
  border-radius: 10px !important;
}
.br-11 {
  border-radius: 11px !important;
}
.br-12 {
  border-radius: 12px !important;
}
.br-13 {
  border-radius: 13px !important;
}

/* WIDTH */
.w-30 {
  width: 30px;
}

.w-160 {
  width: 160px;
}

.w-300 {
  width: 500px;
}

/* FONT  */
/* FONT SIZE */
.f-10 {
  font-size: 10px !important;
}
.f-12 {
  font-size: 12px !important;
}
.f-14 {
  font-size: 14px !important;
}
.f-16 {
  font-size: 16px !important;
}
.f-18 {
  font-size: 18px !important;
}
.f-20 {
  font-size: 20px !important;
}
.f-40 {
  font-size: 40px !important;
}

/* FONT WEIGHT */
.fw-300 {
  font-weight: 300 !important;
}
.fw-400 {
  font-weight: 400 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-700 {
  font-weight: 700 !important;
}
.fw-800 {
  font-weight: 800 !important;
}

/* DISPLAY */
.display-block {
  display: block !important;
}
.display-none {
  display: none !important;
}
.display-inline-block {
  display: inline-block !important;
}
.display-flex {
  display: flex;
}

/* HEIGHT */
.h-10 {
  height: 10px;
}
.h-15 {
  height: 15px;
}
.h-15 {
  height: 15px;
}
.h-20 {
  height: 20px;
}
.h-25 {
  height: 25px;
}
.h-30 {
  height: 30px;
}
.h-35 {
  height: 35px;
}
.h-45 {
  height: 45px;
}
.h-50 {
  height: 50px;
}
.h-55 {
  height: 55px;
}
.h-60 {
  height: 60px;
}
.h-65 {
  height: 65px;
}
.h-70 {
  height: 70px;
}
.h-75 {
  height: 75px;
}
.h-80 {
  height: 80px;
}
.h-85 {
  height: 85px;
}

/* COLORS */
.primary {
  color: #1bbc9b !important;
}
.info {
  color: #03a9f4 !important;
}
.secondary {
  color: #eff4f7 !important;
}
.success {
  color: #1bbc9c !important;
}
.bg-primary {
  background: #1bbc9b !important;
}
.bg-success {
  background: #1bbc9c !important;
}
.bg-info {
  background: #03a9f4 !important;
}
.bg-secondary {
  background: #eff4f7 !important;
}
.bg-orange {
  background: #f5a623 !important;
  color: white !important;
}
div .bg-orange {
  background: #f5a623 !important;
  color: white !important;
}

/* HOVERS */
.hover-grey:hover {
  color: #e3e3e3 !important;
}

.hover-bg-grey:hover {
  background: #e3e3e3 !important;
}
.hover-bg-blue:hover {
  background: #0292d4 !important;
}
