.client-edit-mid-tab > li.active > a {
  border: none !important;
}

.client-edit-mid-tab > li {
  border: 1px solid #1059d3;
  margin: 2px;
  border-radius: 4px;
}

.client-edit-mid-tab > li > a {
  border: none !important;
  color: #1059d3;
  margin-top: 3px;
  font-weight: bold;
}

.client-edit-mid-tab > li.active > a {
  border: none !important;
  background: #1059d3 !important;
  color: #fff !important;
}

.client-edit-mid-tab > li.active {
  border: none !important;
  color: #fff;
  background: #1059d3 !important;
  padding-bottom: 2px;
}

.client-edit-mid-tab > li:hover {
  color: #fff;
  background: #1059d3 !important;
}
.client-edit-mid-tab > li:hover > a {
  color: #fff;
}

.client-edit-mid-tab > li > a:hover {
  color: #fff;
  background: #1059d3 !important;
}

.client-edit-mid-tab > li:hover {
  background: #1059d3 !important;
  color: #fff !important;
}
