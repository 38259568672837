.terms-wrapper > header {
  background-color: white;
  padding: 5px;
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
}

.terms-wrapper a {
  color: #1059d3;
}

.terms-wrapper .subheader {
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;
  color: #000;
}

.terms-wrapper .line {
  background-color: #080435;
  color: #080435;
  text-align: center;
  height: 2px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 25%;
}

.terms-wrapper ul {
  padding-top: 10px;
}

.terms-wrapper ul li {
  margin-top: 10px;
}

.terms-wrapper > header > div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 20px;
}

.terms-wrapper > header > div > a {
  padding: 10px 30px;
  margin: 5px;
}

.terms-wrapper > header > a > img {
  height: 100px;
  cursor: pointer;
}

h2.title {
  font-size: 1.5em;
  font-weight: bold;
  color: black;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.terms-wrapper > main > section.content.title {
  padding-top: 120px;
  padding-bottom: 45px;
  background-color: #080435;
  color: #fff;
  padding-left: 50px;
}

.terms-wrapper > main > section.content.title > .container > h2 {
  font-size: 2.5em;
  font-weight: bold;
  color: #fff;
}

.terms-wrapper > main > section.content.title > .container > h3 {
  font-weight: bold;
  color: #f2f2f2;
}

.terms-wrapper > main > section.content {
  padding-top: 45px;
  padding-bottom: 45px;
  background-color: #ffffff;
}

.terms-wrapper > main > section.content > .container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  color: #767676;
  font-size: 1.2em;
}

@media (min-width: 1151) {
  .terms-wrapper > main > section.content > .container {
    max-width: 1024px;
  }
}

@media (min-width: 992px) {
  .terms-wrapper > main > section.content > .container {
    max-width: 960px;
  }
}

@media (min-width: 768px) {
  .terms-wrapper > main > section.content > .container {
    max-width: 720px;
  }
}

/* @media (min-width: 576px) {
  .terms-wrapper > main > section.content > .container {
    max-width: 540px;
  }
} */

.terms-wrapper > footer {
  text-align: center;
  padding: 40px;
}

.terms-wrapper .cta-container {
  text-align: center;
  margin-top: 20px;
}

.terms-wrapper .cta-container > a {
  padding: 10px 30px;
  margin: 5px;
}
