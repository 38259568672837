.logo {
  height: 40px;
  margin-top: 6px !important;
  padding: 0px;
}

.pt-12 {
  padding-top: 12px !important;
}

.pb-12 {
  padding-bottom: 12px !important;
}
