span.chip{
    background-color: #1059d3!important;
    white-space: initial!important;
}

.optionContainer > li:hover{
    background-color: #1059d3!important;
}

.optionContainer .highlight{
    background-color: #1059d3!important;
}