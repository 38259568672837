.client-edit-tabs {
  border-radius: 4px;
  margin: 2px;
  border: none !important;
}

.client-edit-tabs > li {
  border: 1px solid #1059d3;
  margin: 2px;
  border-radius: 4px;
}

.client-edit-tabs > li:hover {
  color: #fff;
  background: #1059d3 !important;
}

.client-edit-tabs > li.active {
  border: none !important;
  color: #fff;
  background: #1059d3 !important;
  padding: 1px;
}
.client-edit-tabs > li.active > a {
  border: none !important;
  background: #1059d3 !important;
  color: #fff !important;
  padding: 7px !important;
}

.client-edit-tabs > li > a {
  border: none !important;
  color: #1059d3;
  font-weight: bold;
  font-size: 1em !important;
  padding: 7px !important;
}
.client-edit-tabs > li > a:hover {
  color: #fff;
  background: #1059d3 !important;
  padding: 7px !important;
}
